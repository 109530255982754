<template>
    <div>
        <v-navigation-drawer 
            v-model="detailPanel.opened" 
            absolute 
            right 
            clipped 
            :width="drawerWidth" 
            z-index="1"
        >

            <v-card 
                v-if="detailPanel.content" 
                flat 
                class="drawer-full-height"
            >

                <div class="drawer-header">
                    <div class="inv-object-button-group">
                        
                        <v-tooltip top>
                            <template v-slot:activator="{on}">
                                <v-btn  
                                    icon
                                    v-on="on"
                                    color="error"
                                    @click="closeDrawer" 
                                >
                                    <v-icon>fal fa-times</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                {{'close' | translate}}    
                            </span>
                        </v-tooltip>
                        
                        <v-tooltip top>
                            <template v-slot:activator="{on}">
                                <v-btn 
                                    icon
                                    v-on="on"
                                    :disabled="!detailPanel.saveable"
                                    :elevation="detailPanel.saveable ? 24 : 0"
                                    :outlined="detailPanel.saveable"
                                    @click="saveDetailPanelForm"
                                    color="success" 
                                >
                                    <v-icon>fal fa-save</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                {{'save'|translate}}
                            </span>
                        </v-tooltip>
                        
                    </div>

                    <inv-object-form    
                        v-if="detailPanel.content.fieldsGrouped"
                        :groups="detailPanel.content.fieldsGrouped" 
                        @field-input="(field, value) => changeDetailsFieldValue(field, value)" 
                        :edit-mode="true" 
                        :edit-mode-switch="true" 
                        :object-id="null"
                    />
                </div>
                <div 
                    class="drawer-content" 
                    v-if="detailPanel.content"
                >
                    <inv-object-tabs
                        v-if="detailPanel.content.panels && detailPanel.content.panels.length > 0" 
                        :object="detailPanel.content" 
                        :tab-states="objectTabStates" 
                        :readonly="false" 
                        @tab-states-changed="tabStatesChanged" 
                    />
                </div>
            </v-card>
        </v-navigation-drawer>

        <v-dialog
            v-model="modal.opened" 
            persistent
            content-class="modal-dialog"
            width="unset"
            scrollable
        >
            
            <v-card flat>
                <v-card-title >
                    {{modal.title || modal.displayString}}
                </v-card-title>
                
                <v-card-text class="mb-2">
                    <inv-object-form    
                        v-if="modal.fieldsGrouped"
                        :groups="modal.fieldsGrouped"  
                        :object-id="modal.globalId"
                        :edit-mode="true" 
                        :edit-mode-switch="true"
                        @field-input="(field, value) => changeModalsFieldValue(field, value)" 
                        :col-gap="16"
                        :grid="false"
                    />

                    <div v-if="modal.fetchedHtml" v-html="modal.fetchedHtml"/>

                    <div v-if="modal.confirmText" v-html="modal.confirmText"/>
                
                </v-card-text>
                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        text
                        color="error" 
                        @click="closeModal()"
                    >
                        {{modal.closeString || 'cancel' |translate}}
                    </v-btn>
                    <v-btn 
                        outlined 
                        color="success" 
                        v-if="modal.buttonDisplayString" 
                        :disabled="checkDisabledButton()" 
                        @click="runModalBackendAction()"
                    >
                        {{modal.buttonDisplayString}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import object from 'obj-fe/utils/object';
import backend from 'obj-fe/app/backend';
import notify from 'obj-fe/services/notifications';

import wizardService from '../wizard/service';

import InvObjectForm from '../inventory/inv-object-form.vue'

export default {
    components:{
        // removes circular dependency errors
        InvObjectTabs: () => import('../inventory/inv-object-tabs.vue'),
        InvObjectForm
    },
    props:{
        button: Object
    },
    watch:{
        button:{
            handler(button){
                if(button) {
                    this.initiateButtonAction(button)
                }
                else this.initData()
            }
        }
    },
    data(){
        return {
            objectTabStates: {},
            detailPanel: {
                opened: false,
                content: null,
                saveable: false
            },
            modal: {
                opened: false,
            },
        }
    },
    computed:{
        drawerWidth() {
            return Math.min(window.innerWidth / 1.5, 1600);
        }
    },
    methods:{
        initData(){
            this.detailPanel = {
                opened: false,
                content: null,
                saveable: false,
            };
            this.modal = {
                opened: false
            };

        },
        initDetailPanel(button){
            
            let beContext = [];
            let clonedButton = object.deepClone(button);

            if(clonedButton.fieldsGrouped) clonedButton.fieldsGrouped.forEach(fg=>{
                    if(fg.fields) fg.fields.forEach(field => beContext.push(field));
                })

            this.detailPanel = {
                content: clonedButton,
                beContext: beContext,
                saveable: false,
            }
        },
        tabStatesChanged(newStates){
            this.objectTabStates = newStates;
        },
        // depending on the type of the be-button, prepares either 
        // - modal dialog, 
        // - drawer
        // - simply calls a restUrl    
        initiateButtonAction(button){
            if(button.feType === "DETAIL_PANEL_BUTTON"){
                this.initDetailPanel(button);
                this.detailPanel.opened = true;
            }
            else if(button.feType === "LINK_ONLY_BUTTON"){
                let enrichedRestUrl = this.replaceStringAll(button.restUrl,'{id}', button.globalId);
                backend.objects.customPostRestUrl(
                    { customRestUrl: enrichedRestUrl }, // custom rest url for rest-service
                    [], // data to be sent 
                    response => { this.processActionResponse(response); this.$emit('closed'); }, 
                    response => notify.error(response.message)
                );
            }
            else if(button.feType === "SIMPLE_BUTTON"){
                this.simpleButtonAction(button);
                this.modal.opened = true;
            }
            else if(button.feType === "SHOW_HTML_BUTTON"){
                this.showHtmlButtonAction(button);
            }
            else if(button.feType === "START_WIZARD_BUTTON"){
                wizardService.start(button.wizardName);
            }
            else if(button.feType === "CONFIRM_REST_BUTTON"){
                this.showConfirmModal(button);
            }
        },
        // only used with modals
        runModalBackendAction(){
            let enrichedRestUrl = this.replaceStringAll(this.modal.restUrl,'{id}', this.modal.globalId);
            backend.objects.customPostRestUrl(
                {customRestUrl: enrichedRestUrl}, // custom rest url for rest-service
                this.modal.beContext, // data to be sent 
                response => this.processActionResponse(response), 
                response => {
                    notify.error(response)
                }
            );
            this.closeModal();
        },
        closeDrawer(){
            this.objectTabStates = {};
            this.initData();
            this.$emit('closed');
        },
        closeModal(){
            this.initData();
            this.$emit('closed');
        },
        simpleButtonAction(button){
            let beContext = [];
            let clonedButton = object.deepClone(button);

            clonedButton.fieldsGrouped.forEach(fg=>{
                fg.fields.forEach(field => beContext.push(field));
            })
            this.modal = {
                ...clonedButton,
                beContext: beContext,
            };
        },
        showHtmlButtonAction(button){
            if(!button.restUrl) return;
            
            backend.objects.customGetRestUrl(
                { customRestUrl: button.restUrl }, 
                (data) => this.processHtmlData(data, button)
            );
        },
        processHtmlData(data, button){
            this.modal = {
                ...object.deepClone(button),
                fetchedHtml: data,
                closeString: 'close',
                opened: true
            };
        },
        showConfirmModal(button){
            this.modal = {
                title: button.title,
                confirmText: button.displayString,
                buttonDisplayString: button.buttonDisplayString,
                restUrl: button.restUrl,
                closeString: 'close',
                opened: true
            };
        },
        changeModalsFieldValue(field, value){
            let fieldIndex = this.modal.beContext.map(e=>e.attribute).indexOf(field.attribute);
            if(fieldIndex > -1) this.modal.beContext[fieldIndex].value = value;
        },
        checkDisabledButton(){
            if(!this.modal.beContext) return false;

            let emptyRequiredFieldsCount = this.modal.beContext
                .filter(e=>e.required)
                .filter(e=>!e.value)
                .filter(e=>e.value !== 0)
                .length;
            if(emptyRequiredFieldsCount > 0) return true;
            else return false;
        },
        processActionResponse(response){
            if(response.type === 'MESSAGE') {
                notify.success(response.message);
            }
            else if(response.type === 'LOAD_RESOURCE') {
                notify.success(response.message);

                this.$router.push({
                    name: 'inventory-object',
                    params: {
                        id: response.resourceId
                    }
                });
            }
            else if(response.type === 'RELOAD_GRID') {
                notify.success(response.message);
                this.$emit('grid-reload-needed');
            }

            // eligible only for objects
            else if(response.type === 'RELOAD_RESOURCE'){
                notify.success(response.message);
                this.$emit('resource-reload-needed');
            } 
            
            else console.error('Response type unidentifiable');
        },
        changeDetailsFieldValue(field, value){
            this.detailPanel.saveable = true;

            let fieldIndex = this.detailPanel.beContext.map(e=>e.attribute).indexOf(field.attribute);
            if(fieldIndex > -1) this.detailPanel.beContext[fieldIndex].value = value;
        },
        saveDetailPanelForm(){
            this.runDetailPanelBackendAction();
        },
        runDetailPanelBackendAction(){
            if(!this.detailPanel.content.restUrl) return

            backend.objects.customPostRestUrl(
                {customRestUrl: this.detailPanel.content.restUrl}, // custom rest url for rest-service
                this.detailPanel.beContext, // data to be sent 
                response => this.processActionResponse(response), 
                response => notify.error(response.message)
            );
            this.closeDrawer();
        },
        replaceStringAll(str, whatStr, withStr){
            var regexp = new RegExp( this.escapeRegExp(whatStr), 'g' );
            return str.replace(regexp, withStr);
        },
        escapeRegExp(str) {
            return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        }
    }
}
</script>

<style scoped>
.drawer-full-height{
    height: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
}
.drawer-full-height .drawer-header{
    flex: 0 1 auto;
}
.drawer-full-height .drawer-header form{
    height: unset;
}
.drawer-full-height .drawer-content{
    flex: 1 1 auto;
}
.drawer-full-height .drawer-content > div{
    height: 100%;
}
.v-navigation-drawer--close{
    right: -8px;
}
</style>