import Row from './row.js';
import objectAsArray from './object-as-array.js';

export default {
    props:{
        addRowDisabled: Boolean,
        rowContexts: Function
    },
    data(){
        return {
            rows:{},

            watcherResolvingActive: false,
            // changesWhileWatchersResolving: null, // exclude from vue reactivity
            adhocWatchers:{},

            // changesHistory:[], // exclude from vue reactivity
            // changesHistoryIndex:-1, // exclude from vue reactivity
            // maxChangesHistory: 50, // exclude from vue reactivity

            currRowContexts:{}
        };
    },
    methods:{
        setRows(rows){
            this.rows = objectAsArray(rows);
        },
        addRow(afterRowIndex, rowData){
            if(arguments.length <= 1){
                rowData = arguments[0];
                afterRowIndex = this.rows.length-1;
            }

            if(rowData = true) {
                let rowType = (this.rows[afterRowIndex] || {}).rowType || this.defaultRowType;
                rowData = { rowType };
            }
            this.addRows(afterRowIndex, [ rowData || {} ]);
        },
        
        addRows(afterRowIndex, rows, dontRegisterChanges, forceIfDisabled){
            if(this.addRowDisabled && !forceIfDisabled) return;
            if(arguments.length <= 1){
                rows = arguments[0] || [];
                afterRowIndex = this.rows.length-1;
            }

            let rowType = (this.rows[afterRowIndex] || {}).rowType || this.defaultRowType;

            rows = rows.map(row => {
                row.rowType = row.rowType || rowType;
                return new Row(row, this.ignoreRowProps);
            });

            if(!dontRegisterChanges) this.registerChanges([{
                addRows: rows,
                fromRowIndex: afterRowIndex+1,
                toRowIndex: afterRowIndex+rows.length
            }], true);

            let args = [afterRowIndex+1, 0].concat(rows);
            this.rows.splice.apply(this.rows, args);

            let addedRowIndexes = [];
            for(let i=afterRowIndex+1;i<afterRowIndex+1+rows.length;i++) addedRowIndexes.push(i);
            this.triggerChangeWatchersForRows(addedRowIndexes);
        },

        // switch rows between row positions
        switchRows(rowIndexA, rowIndexB, dontRegisterChanges){
            let rowA = this.rows[ rowIndexA ];
            let rowB = this.rows[ rowIndexB ];

            if(!rowA || !rowB) return;

            this.$set(this.rows, rowIndexA, rowB);
            this.$set(this.rows, rowIndexB, rowA);

            // TODO: run all watchers ?
            if(!dontRegisterChanges) this.registerChanges([{
                switchRows: true,
                rowIndexA,
                rowIndexB
            }], true);

            this.triggerChangeWatchersForRows([ rowIndexA, rowIndexB ]);
        },

        removeRows(fromRowIndex, toRowIndex, dontRegisterChanges){
            let rowIndexes;
            if(Array.isArray(fromRowIndex)) {
                rowIndexes = fromRowIndex.map(i => parseInt(i, 10)).sort((a,b) => a > b ? 1 : a < b ? -1 : 0);
                dontRegisterChanges = toRowIndex;

                // split row removals to separate parts
                let startRowIndex = rowIndexes[0];
                rowIndexes.forEach((rowIndex, i) => {
                    let nextRowIndex = rowIndexes[i+1];

                    if((nextRowIndex === undefined) || (nextRowIndex !== rowIndex+1)) {
                        this.removeRows(startRowIndex, rowIndex, dontRegisterChanges);
                        startRowIndex = nextRowIndex;
                    }
                });

                return;
            }
            else {
                fromRowIndex = parseInt(fromRowIndex, 10);
                toRowIndex = parseInt(toRowIndex, 10);
                rowIndexes = new Array((toRowIndex+1) - fromRowIndex).fill().map((v,i) => fromRowIndex+i);
            }

            let rowsToRemove = [];
            rowIndexes.forEach(rowIndex => {
                let row = this.rows[rowIndex];
                rowsToRemove.push(row);
            });

            if(!dontRegisterChanges) this.registerChanges([{
                removeRows: rowsToRemove,
                fromRowIndex,
                toRowIndex
            }], true);

            // remove rows
            rowsToRemove.forEach(row => {
                let rowIndex = this.rows.indexOf(row);
                if(rowIndex > -1) {
                    this.removeAllCellErrorsInRow(rowIndex);
                    this.rows.splice(rowIndex, 1);
                }
            });

            // rows changed, need to recalc row contexts and trigger changes if there are some
            this.recalcRowContexts();
            let changes = this.generateChangesBasedOnRowContexts();

            if(changes.length > 0){
                if(!dontRegisterChanges) this.registerChanges(changes);
                else this.triggerChangeWatchers(changes, this.runWatchersDoneQueue);
            }
            else this.checkValidState();
        },

        getColumn(rowType, colIndex){
            if(arguments.length === 1 && !isNaN(rowType)) {
                colIndex = rowType;
                rowType = this.getRow(0)?.rowType;
            }
            return this.columns[rowType || 'default'][colIndex];
        },
        getRow(rowIndex){
            return this.rows[rowIndex];
        },
        getCellInitValue(rowIndex, colIndex){
            let rowType = this.getRow(rowIndex).rowType;
            let dataKey = this.getColumn(rowType, colIndex).dataKey;
            return this.getRow(rowIndex)._initValue[dataKey];
        },
        getCellValue(rowIndex, colIndex){
            let rowType = this.getRow(rowIndex).rowType;
            return this.getRow(rowIndex)[ this.getColumn(rowType, colIndex).dataKey ];
        },
        getCellColumn(rowIndex, colIndex){
            let rowType = this.getRow(rowIndex).rowType;
            return this.getColumn(rowType, colIndex);
        },
        refreshRow(rowIndex){
            this.triggerChangeWatchersForRows([rowIndex]);
        },
        setCellValue(rowIndex, colIndex, value, dontRegisterChange, forceSet){
            if(!forceSet && this.isCellReadonly(rowIndex, colIndex)) return;

            if(this.watcherResolvingActive && !dontRegisterChange) {
                dontRegisterChange = true;
                this.changesWhileWatchersResolving = this.changesWhileWatchersResolving || [];
                this.changesWhileWatchersResolving.push({ rowIndex, colIndex, value });
            }

            let row = this.getRow(rowIndex);
            let rowType = row.rowType;
            let dataKey = this.getColumn(rowType, colIndex).dataKey;
            let oldValue = row[dataKey];

            if(!dataKey) return; // do nothing when dataKey not defined

            // row type is required by default and can be only one from header rows keys
            if(dataKey === 'rowType' && this.headerRowIndexesByType[value] === undefined) return;

            let valueNotChanged =   (oldValue === undefined && value === '') || 
                                    (oldValue === '' && value === undefined) || 
                                    (oldValue === value);
            
            if(valueNotChanged) return;

            this.$set(row, dataKey, value);
            // this.$set(this.rows[rowIndex], dataKey, value);

            let change = { rowIndex, colIndex, value, oldValue };
            if(dontRegisterChange) return change;
            else this.registerChanges([change]);
        },
        bulkSetCellValue(cellValues, dontRegisterChanges, forceSet){
            let changes = [];
            if(cellValues.length === 0) return;
            cellValues.forEach(cellValue => {
                let change = this.setCellValue(cellValue.rowIndex, cellValue.colIndex, cellValue.value, true, forceSet);
                if(change) changes.push(change);
            });
            if(!dontRegisterChanges) this.registerChanges(changes);
        },
        registerChanges(changes, isRowChanges){
            if(this.changesHistoryIndex < this.changesHistory.length-1) {
                this.changesHistory = this.changesHistory.slice(0, this.changesHistoryIndex+1);
            }
            this.changesHistoryIndex = this.changesHistory.push({ changes, isRowChanges, selectedCells: { ...this.selectedCells } }) - 1;
            if(this.changesHistoryIndex > this.maxChangesHistory) {
                this.changesHistory.shift();
                this.changesHistoryIndex--;
            }

            if(!isRowChanges) this.triggerChangeWatchers(changes, this.runWatchersDoneQueue);
        },
        undo(){
            if(this.changesHistoryIndex < 0) return;
            this.recoverHistoryState(true);
            this.changesHistoryIndex--;
        },
        redo(){
            if(this.changesHistoryIndex === this.changesHistory.length-1) return;
            this.changesHistoryIndex++;
            this.recoverHistoryState();
        },
        resetChangeHistory(){
            this.changesHistory = [];
            this.changesHistoryIndex = -1;
        },
        recoverHistoryState(recoveryOldValues){
            let state = this.changesHistory[this.changesHistoryIndex];
            let changes = state.changes;
            let isRowChanges = state.isRowChanges;

            if(isRowChanges){
                changes.forEach(change => {
                    if(recoveryOldValues){
                        if(change.switchRows) this.switchRows(change.rowIndexB, change.rowIndexA, true);
                        else if(change.addRows) this.removeRows(change.fromRowIndex, change.toRowIndex, true);
                        else if(change.removeRows) this.addRows(change.fromRowIndex-1, change.removeRows, true);
                    }
                    else {
                        if(change.switchRows) this.switchRows(change.rowIndexA, change.rowIndexB, true);
                        else if(change.addRows) this.addRows(change.fromRowIndex-1, change.addRows, true);
                        else if(change.removeRows) this.removeRows(change.fromRowIndex, change.toRowIndex, true);
                    }
                });

                this.selectedCells = state.selectedCells;
                return;
            }

            if(recoveryOldValues) changes = state.changes.map(change => { return { ...change, value:change.oldValue, oldValue:change.value } });
            
            this.bulkSetCellValue(changes, true, true);
            this.selectedCells = state.selectedCells;
            this.triggerChangeWatchers(changes, this.runWatchersDoneQueue);
        },
        // rowIsEmpty(rowIndex, skipColIndexes = []){
        //     let row = this.getRow(rowIndex);

        //     for(let i=0;i<this.columns[row.rowType].length;i++) {
        //         if(skipColIndexes.indexOf(i) === -1) {
        //             let dataKey = this.columns[row.rowType][i].dataKey;
        //             if(row[dataKey] !== undefined && row[dataKey] !== '') return false;
        //         }
        //     }
        //     return true;
        // },

        triggerAllChangeWatchers(){
            let rowIndexes = this.rows.map((r,i) => i);
            this.triggerChangeWatchersForRows(rowIndexes);
        },

        triggerChangeWatchersForColumns(colIndexesByRowType){
            if(Array.isArray(colIndexesByRowType)) colIndexesByRowType = { default:colIndexesByRowType };

            let changes = [];
            this.rows.forEach((row, rowIndex) => {
                colIndexesByRowType[row.rowType].forEach(colIndex => {
                    changes.push({ rowIndex, colIndex, value:this.getCellValue(rowIndex, colIndex) });
                });
            });

            this.triggerChangeWatchers(changes, this.runWatchersDoneQueue);
        },

        triggerChangeWatchersForRows(rowIndexes){
            if(rowIndexes.length === 0) return;

            let changes = [];
            rowIndexes.forEach(rowIndex => {
                let columns = this.columns[ this.getRow(rowIndex).rowType ] || [];
                columns.forEach((col, colIndex) => {
                    changes.push({ rowIndex, colIndex, value:this.getCellValue(rowIndex, colIndex) });
                });
            });
            this.triggerChangeWatchers(changes, this.runWatchersDoneQueue);
        },

        triggerChangeWatchers(changes, done){
            this.watcherResolvingActive = true;

            // recalc row contexts on every time some value changes
            this.recalcRowContexts();

            // let spreadsheet = this;
            let changesByRowIndex = {};
            let changesByRowAndColIndex = {};

            let rowIndexesWithTypeChange = [];
            changes = changes.slice();

            let changedDataKeysByRowIndex = {};
            let storeChangedDataKeyRef = (change) => {
                let rowIndex = change.rowIndex;
                let colIndex = change.colIndex;

                let rowType = this.getRow(rowIndex).rowType;
                let dataKey = this.getColumn(rowType, colIndex).dataKey;
                if(!changedDataKeysByRowIndex[rowIndex]) changedDataKeysByRowIndex[rowIndex] = {};
                changedDataKeysByRowIndex[rowIndex][dataKey] = true;
            };

            changes.forEach(change => {
                let rowIndex = change.rowIndex;
                let colIndex = change.colIndex;

                if(!changesByRowIndex[ rowIndex ]) changesByRowIndex[ rowIndex ] = [];
                changesByRowIndex[ rowIndex ].push(change);

                let rowColKey = rowIndex + ':' + colIndex;
                changesByRowAndColIndex[ rowColKey ] = true;

                let rowType = this.getRow(rowIndex).rowType;
                let dataKey = this.getColumn(rowType, colIndex).dataKey;
                
                if(dataKey === 'rowType') rowIndexesWithTypeChange.push(rowIndex);
                else storeChangedDataKeyRef(change);
            });

            // generate changes for each cell in row when rowType changes
            // because it needs to re-validate every cell by rules belonging to another columns
            rowIndexesWithTypeChange.forEach(rowIndex => {
                let rowType = this.getRow(rowIndex).rowType;
                this.columns[rowType].forEach((col, colIndex) => {
                    if(changesByRowAndColIndex[rowIndex + ':' + colIndex]) return;

                    let change = { colIndex, rowIndex, value:this.getCellValue(rowIndex, colIndex) };
                    changes.push(change);
                    changesByRowIndex[rowIndex].push(change);
                    storeChangedDataKeyRef(change);
                });
            });

            // add changes triggered by row context changes
            this.generateChangesBasedOnRowContexts(rowIndexesWithTypeChange).forEach(change => {
                if(changesByRowAndColIndex[change.rowIndex + ':' + change.colIndex]) return;
                changes.push(change);
                changesByRowIndex[change.rowIndex] = changesByRowIndex[change.rowIndex] || [];
                changesByRowIndex[change.rowIndex].push(change);
                storeChangedDataKeyRef(change);
            });

            // add changes for column relations in row
            for(let rowIndex in changedDataKeysByRowIndex){
                let row = this.getRow(rowIndex);

                for(let dataKey in changedDataKeysByRowIndex[rowIndex]){
                    if(row._state.cellDataWatchers[dataKey]) {

                        for(let colIndex in row._state.cellDataWatchers[dataKey]){
                            
                            if(!changesByRowAndColIndex[rowIndex + ':' + colIndex]) {
                                // check if column exists, because columns length may change
                                if(this.getCellColumn(rowIndex, colIndex)) {
                                    let change = { colIndex, rowIndex, value:this.getCellValue(rowIndex, colIndex) };
                                    changes.push(change);
                                }
                            }
                        }
                    }
                }
            }

            // TODO: empty row must behave like non-empty - all validations must be run
            // // special case, when row become non-empty, change must trigger on all columns
            // // and opposite, when row become empty, it must clear all cell errors on this row
            // for(let rowIndex in changesByRowIndex) {
            //     let rowChanges = changesByRowIndex[rowIndex];

            //     if(this.rowIsEmpty(rowIndex)){ // row becomes empty
            //         this.columns.forEach((col, colIndex) => {
            //             this.removeAllCellErrors(rowIndex, colIndex);
            //         });
            //         rowChanges.forEach(change => changes.splice(changes.indexOf(change), 1));
            //     }
            //     else if(rowChanges.filter(change => change.oldValue !== '' && change.oldValue !== undefined).length === 0){ // all changes are from empty to something
            //         let changedColIndexes = rowChanges.map(change => change.colIndex);
            //         if(this.rowIsEmpty(rowIndex, changedColIndexes)){ // all non changed cells are empty
            //             // row was empty before and now becomes non empty
                    
            //             // generate fake changes for rest of row cells to ensure watchers will be triggered
            //             this.columns.forEach((col, colIndex) => {
            //                 if(changedColIndexes.indexOf(colIndex) > -1) return;
            //                 let cellValue = this.getCellValue(rowIndex, colIndex);
            //                 changes.push({ rowIndex, colIndex, value:cellValue, oldValue:cellValue });
            //             });
            //         }
            //     }
            // }

            let changesByRowTypeAndColIndex = {};
            changes.forEach(change => {
                let rowIndex = change.rowIndex;
                let colIndex = change.colIndex;

                let row = this.getRow(rowIndex);
                this.removeCellErrors(rowIndex, colIndex);
                let rowType = row.rowType;
                if(!changesByRowTypeAndColIndex[ rowType ]) changesByRowTypeAndColIndex[ rowType ] = {};
                if(!changesByRowTypeAndColIndex[ rowType ][ colIndex ]) changesByRowTypeAndColIndex[ rowType ][ colIndex ] = [];
                changesByRowTypeAndColIndex[ rowType ][ colIndex ].push(change);
            });

            // run default watchers
            let watcherQueues = {};

            for(let rowType in changesByRowTypeAndColIndex){
                for(let colIndex in changesByRowTypeAndColIndex[rowType]){
                    let col = this.columns[rowType][colIndex];

                    // watchersQueue is ordered as watchers object properties, so dataType will be allways first, no matter how column props are ordered
                    for(let watcherId in this.columnWatchers){

                        let watcher = this.columnWatchers[watcherId];

                        if(col[watcherId] !== undefined || watcher.global) {
                            watcherQueues[watcherId] = watcherQueues[watcherId] || [];
                            if(watcher.runPerChange) changesByRowTypeAndColIndex[rowType][colIndex].forEach(change => watcherQueues[watcherId].push({ watcherId, watcher, opts:col[watcherId], change:change }));
                            else watcherQueues[watcherId].push({ watcherId, watcher, opts:col[watcherId], changes:changesByRowTypeAndColIndex[rowType][colIndex] });
                        }
                    }
                }
            }

            let finished = () => {
                this.watcherResolvingActive = false;
                
                if(this.changesWhileWatchersResolving) {
                    let changesToCheck = this.changesWhileWatchersResolving.slice();
                    this.changesWhileWatchersResolving = null;
                    this.triggerChangeWatchers(changesToCheck, done);
                }
                else if(done) done();
            };

            function runQueueSeries(queue, thisCtx, done, currIndex = 0){
                let watch = queue[currIndex];
                let isAsync = watch.watcher.async;

                if(isAsync) {
                    let cbCalled = false;
                    
                    watch.watcher.handler.call(thisCtx, watch.opts, watch.changes || watch.change, function(){
                        if(cbCalled) throw new Error('"done()" callback called more than once, please check your code');
                        cbCalled = true;
    
                        if(currIndex < (queue.length - 1)) runQueueSeries(queue, thisCtx, done, currIndex+1);
                        else done();
                    });
                }
                else {
                    for(let i=0;i<queue.length;i++){
                        watch = queue[i];
                        watch.watcher.handler.call(thisCtx, watch.opts, watch.changes || watch.change);
                    }

                    done();
                }
            }

            let queuesToResolve = Object.keys(watcherQueues).length;
            if(queuesToResolve === 0) return finished();

            for(let watcherId in watcherQueues){
                let queue = watcherQueues[watcherId];

                runQueueSeries(queue, this, () => {
                    watcherQueues[watcherId] = []; // release queue from memory
                    queuesToResolve--;
                    if(queuesToResolve === 0) finished();
                });
            }
        },
        triggerColumnWatchers(colIndexes, currChange, done){ // manually trigger watchers on another columns
            let changes = colIndexes.map(colIndex => {
                let cellValue = this.getCellValue(currChange.rowIndex, colIndex);
                return {
                    colIndex,
                    rowIndex: currChange.rowIndex,
                    value: cellValue,
                    oldValue: cellValue
                };
            });
            this.triggerChangeWatchers(changes, done);
        },

        runWatchersDoneQueue(){
            // run in queue async in series
            this.$nextTick(() => {
                while(this.onWatchersDoneQueue){
                    this.onWatchersDoneQueue.shift()();
                    if(this.onWatchersDoneQueue.length === 0) this.onWatchersDoneQueue = null;
                    // else this.runWatchersDoneQueue();
                }

                this.checkValidState();
            });
        },

        checkValidState(){
            let isValid = !this.hasCellErrors();
            this.$emit('is-valid', isValid);
            this.$emit('is-changed', this.rows.filter(row => row.hasChanged()).length > 0);
            this.$emit('change', this.rows);
        },

        watch(rowIndex, colIndex, cb){
            this.$set(this.adhocWatchers, (rowIndex || '') + ':' + (colIndex || ''), cb);
        },

        unwatch(rowIndex, colIndex, cb){
            this.$delete(this.adhocWatchers, (rowIndex || '') + ':' + (colIndex || ''));
        },

        onChangeWatchersDone(cb){
            this.onWatchersDoneQueue = this.onWatchersDoneQueue || [];
            this.onWatchersDoneQueue.push(cb);
        },

        recalcRowContexts(){
            if(this.rowContexts) {
                this.rowContexts(this.rows, this.setRowContext);
            }
        },

        setRowContext(rowIndex, newCtx){
            let row = this.getRow(rowIndex);
            if(!row) return; // it was removed already
            let rowId = row._state.id;

            this.currRowContexts[ rowId ] = this.currRowContexts[ rowId ] || { old:{}, new:{}, changedProps:null };
            this.currRowContexts[ rowId ].old = this.currRowContexts[ rowId ].new; // move new context to old
            this.currRowContexts[ rowId ].new = newCtx;
            this.currRowContexts[ rowId ].changedProps = null;
        },

        getRowContext(rowIndex){
            let row = this.getRow(rowIndex);
            if(!row) return {};
            let rowId = row._state.id;
            return (this.currRowContexts[ rowId ] || {}).new || {};
        },

        getRowContextChanges(rowIndex){
            let row = this.getRow(rowIndex);
            if(!row) return {}; // it was removed already

            let rowId = row._state.id;
            let ctxInfo = this.currRowContexts[ rowId ] || {};
            if(ctxInfo.changedProps !== null) return ctxInfo.changedProps;

            // changes not calculated, need to compare
            ctxInfo.changedProps = false;
            let props = new Set(Object.keys(ctxInfo.new).concat(Object.keys(ctxInfo.old)));
            
            props.forEach(prop => {
                if(ctxInfo.new[prop] !== ctxInfo.old[prop]) {
                    ctxInfo.changedProps = ctxInfo.changedProps || {};
                    ctxInfo.changedProps[prop] = true;
                }
            });

            return ctxInfo.changedProps;
        },

        generateChangesBasedOnRowContexts(skipRowIndexes = []){
            let changes = [];
            let skipRowIndexesObj = {};
            skipRowIndexes.forEach(rowIndex => skipRowIndexesObj[rowIndex] = true);

            this.rows.forEach((row, rowIndex) => {
                if(skipRowIndexesObj[rowIndex]) return;
                let ctxPropChanges = this.getRowContextChanges(rowIndex);
                if(!ctxPropChanges) return;

                let cols = this.columns[ row.rowType ];
                cols.forEach((col, colIndex) => {
                    if(!col.rowCtxDepends) return;
                    for(let i=0;i<col.rowCtxDepends.length;i++){
                        if(ctxPropChanges[ col.rowCtxDepends[i] ]) {
                            changes.push({ rowIndex, colIndex, value:this.getCellValue(rowIndex, colIndex) });
                        }
                    }
                });
            });

            return changes;
        }
    }
};