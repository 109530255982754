<template>

    <div style="position:relative;" :style="{ height:height ? height : '100%' }">
        <spreadsheet    v-if="headers && data"
                        :key="reportId"
                        ref="spreadsheet"
                        :data="data"
                        :data-types="customDataTypes"
                        :merge-rows-by-id="mergeAddedRows"
                        :headers="headers"
                        :column-groups="groups"
                        :watchers="watchers" 
                        :ignore-row-props="['_origFields']"
                        add-row-disabled
                        @change="rows => onSpreadsheetChange(rows)"
                        @is-valid="isValid => onSpreadsheetValid(isValid)"
                        :on-row-menu="onRowMenu"
                        :pagination="pagination"
                        @page="setPage"
                        @limit="setLimit"
                        @sort="onSort"
                        @filter="onFilter"
                        fit-width
                        fit-height
                        style="min-height:300px;max-height:100%;"
                        :always-white-cells="isWholeReportReadonly"
                        @be-button-clicked="beButton => $emit('be-button-clicked', beButton)"
                        @active-changed="e => $emit('active-changed', e)"
                        @open-detail="e => $emit('open-detail', e)"
                        :virtual-scroll-on="useVirtualScroll">
        </spreadsheet>

        <slot name="addNewEntityDrawer"/>

        <slot name="deleteModal"/>

        <v-navigation-drawer 
            v-model="editorDrawer.opened" 
            absolute 
            right 
            clipped 
            :width="editorDrawerWidth" 
            z-index="1"
            style="right:-8px"
            floating
            class="object-side-drawer"
        >

            <v-card flat class="fill-height">
                <v-card-title>
                    <v-subheader>{{editorDrawer.title}}</v-subheader>

                    <v-spacer></v-spacer>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="editorDrawer.opened = false"
                                v-on="on"
                                icon
                                outlined
                                class="object-close-button" 
                                data-test="close-button"
                            >
                                <v-icon>fal fa-arrow-right</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{'close' | translate}}
                        </span>
                    </v-tooltip>
                </v-card-title>

                <v-card-text style="height:calc(100% - 68px)">
                    <v-component :is="editorDrawer.component"
                                 :value="editorDrawer.value"
                                 @input="val => editorDrawer.setValue(val)"
                                 :key="editorDrawer.key">
                    </v-component>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>

        <v-dialog 
            v-model="confirmDialogOpened" 
            persistent 
            width="500px"
            content-class="small-dialog"
        >
            <v-card
                data-test="leave-confirm-modal"
            >
                <v-card-title><h2>{{ 'you_have_unsaved_changes' | translate}}</h2></v-card-title>
                <v-card-text>{{'do_you_want_to_reload_without_save' | translate}}</v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn 
                        text
                        color="error"
                        @click="confirmDialogAction(true)" 
                    >
                        {{'reload_without_saving' | translate}}
                    </v-btn>
                    <v-btn 
                        outlined 
                        color="green"
                        @click="confirmDialogAction(false)" 
                    >
                        {{'stay_editing' | translate}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
import object from 'obj-fe/utils/object';
import backend from 'obj-fe/app/backend';
import { globalInvWatchers, dataTypeByEditor, fieldValueByEditor, drawerComponentByFieldEditor } from './spreadsheet-inv-types-mapping.js';

import SpreadsheetRendererExpand from './renderers/spreadsheet-renderer-expand.vue';
import SpreadsheetRendererDefault from './renderers/spreadsheet-renderer-default.vue';
import SpreadsheetRendererOpenDetail from './renderers/spreadsheet-renderer-open-detail.vue';
import SpreadsheetRendererButton from './renderers/spreadsheet-renderer-button.vue';

export default {
    props:{
        reportId: String,
        
        value: Object, // value is query, if not defined, using from reports store
        loadImmediately: Boolean,
        readonly: Boolean,

        activeItem: {},
        openDetailEnabled: Boolean,

        // reloadFilters is used only for dropdown filter, maybe for refresh after new items added
        reloadFilters: Boolean,

        // TODO:
        // description.manualTriggerLoad - is this handled by reports store ?

        customizeHeaders: Function,
        height: {}
    },
    data(){
        return {
            isLoading: false,
            editMode: false,
            dataWasLoaded: false,

            isLoaded: false,
            headers: null,
            data: null,
            mergeAddedRows: false,
            watchers: globalInvWatchers,

            confirmDialogOpened: false,

            customDataTypes:{
                button:{
                    renderer: SpreadsheetRendererButton,
                    editable: false,
                    readonly: true
                },
            },

            editorDrawer:{
                opened: false,
                title: '',
                rowIndex: null,
                colIndex: null,
                value: undefined,
                component: undefined
            }
        };
    },
    watch:{
        reportId:{
            immediate: true,
            handler(val){
                if(!val) return;
                // clear cached data
                this.headers = null;
                this.data = null;
            }
        },
        activeItem(activeItem){
            this.$refs.spreadsheet.setRowActive(row => row === activeItem || row.id === activeItem || row.id === activeItem?.id);
        },
        columns:{
            immediate: true,
            handler(columns){
                this.headers = this.createHeaders(columns);
                if(this.items && !this.data) {
                    this.mergeAddedRows = !!this.items.find(i => i.id < 0);
                    this.data = this.items.map(item => this.transformObjectToRow(item));
                }
            }
        },
        items:{
            immediate: true,
            handler(items){
                if(this.headers) {
                    this.mergeAddedRows = !!items.find(i => i.id < 0);
                    this.data = items.map(item => this.transformObjectToRow(item));
                }
            }
        },
        value:{
            immediate: true,
            handler(val){
                if(!val) return;

                let modifiedQuery = object.extend('data', {}, this.query, val);
                let doLoad = !object.deepEqual(this.query, modifiedQuery) || (this.loadImmediately ? !this.dataWasLoaded : false);

                if(doLoad) {
                    this.dataWasLoaded = true;
                    this.$store.dispatch('REPORTS/SET_REPORT_QUERY', { 
                        reportId: this.reportId,
                        query: modifiedQuery,
                        reload: this.loadImmediately
                    });
                }
            }
        },
        hasDirtyFields:{
            immediate: true,
            handler(val){
                this.$emit('is-dirty', val);
            }
        }
    },
    computed:{
        reportState(){
            return this.$store.state['REPORTS'].reportsById[ this.reportId ] || {};
        },
        description(){
            return this.reportState.description || {};
        },
        isWholeReportReadonly(){
            return !!this.readonly || (!this.description.canCreate && !this.description.canRemove && !this.columns.find(col => ![ null, undefined, 'READ_ONLY' ].includes(col.editor)));
        },
        useVirtualScroll(){
            return !this.columns.find(col => col.editor === 'TEXTAREA' || col.renderer === 'TEXT_WRAP');
        },
        query(){
            return this.reportState.query;
        },

        columns(){
            return this.description.columns || [];
        },
        groups(){
            let colGroups = this.description.groups || [];
            let minOneGroupHasText = colGroups.filter(cg => !!cg.name || cg.count > 1).length > 0;
            
            if(minOneGroupHasText) {
                let headersCountDiff = this.headers.length - colGroups.reduce((count, cg) => count + cg.count, 0);
                if(headersCountDiff > 0) return [{ name:'', count:headersCountDiff }].concat(colGroups);
                else return colGroups;
            }
            else return [];
        },
        columnGroupsDefined(){
            return this.description.groups && this.description.groups.filter(group => !!group.name).length > 0;
        },
        items(){
            return (this.reportState.rowIds || []).map(id => this.reportState.rows[id]);
        },
        removedRows(){
            return this.reportState.removedRows || {};
        },
        pagination(){
            return this.reportState.pagination;
        },
        hasDirtyFields(){
            return Object.keys(this.reportState.removedRows || {}).length > 0 || Object.keys(this.reportState.form || {}).length > 0;
        },
        editorDrawerWidth() {
            return Math.min(window.innerWidth / 2.5, 1600);
        },
    },
    methods:{
        confirmReload(cb){
            if(!this.hasDirtyFields) return cb(true);
            
            this.confirmDialogCb = cb;
            this.confirmDialogOpened = true;
        },
        confirmDialogAction(result){
            this.confirmDialogCb ? this.confirmDialogCb(result) : null;
            this.confirmDialogOpened = false;
        },

        onRowMenu({ row, disableDefaultAction, replaceDefaultAction, rowIndex, selectedCells }){

            const addRows = (rowIndex, rowsCount) => {
                this.$store.dispatch('REPORTS/CREATE_REPORT_ROW', {reportId: this.reportId, rowIndex, rowsCount });
            };

            replaceDefaultAction({
                id:'insert-above',
                title: this.$translate('insert') + ' ' + (selectedCells.rowsCount || 1) + ' ' + this.$translate('above'),
                action(spreadsheet){
                    addRows(spreadsheet.selectedCells.fromRowIndex, spreadsheet.selectedCells.rowsCount);
                },
                disabled: !this.description.canCreate
            });

            replaceDefaultAction({
                id:'insert-below',
                title: this.$translate('insert') + ' ' + (selectedCells.rowsCount || 1) + ' ' + this.$translate('below'),
                action(spreadsheet){
                    addRows(spreadsheet.selectedCells.toRowIndex+1, spreadsheet.selectedCells.rowsCount);
                },
                disabled: !this.description.canCreate,
                divider: true
            });

            replaceDefaultAction({
                id:'remove',
                title: selectedCells.rowsCount === 1 ? this.$translate('delete_row') + ' ' + (selectedCells.fromRowIndex + 1) : this.$translate('delete_rows') + ' ' + (selectedCells.fromRowIndex + 1) + ' - ' + (selectedCells.toRowIndex + 1),
                action(spreadsheet){
                    let fromRowIndex = spreadsheet.selectedCells.fromRowIndex;
                    let toRowIndex = spreadsheet.selectedCells.toRowIndex;

                    let changes = [];
                    new Array(1 + toRowIndex - fromRowIndex).fill().forEach((v,i) => {
                        let rowIndex = fromRowIndex + i;
                        changes.push({ rowIndex, colIndex:0, value:true });
                    });

                    spreadsheet.bulkSetCellValue(changes);
                },
                disabled: !this.description.canRemove,
                divider: true
            });

            return [];
        },
        onSpreadsheetValid(isValid){
            this.$store.dispatch('REPORTS/SET_REPORT_VALID', {
                reportId: this.reportId,
                valid: isValid
            });
        },
        onSpreadsheetChange(rows){
            let fieldsAndValues = [];

            rows.forEach(row => {
                if(row.hasChanged()) {
                    let cellChanges = row.getCellChanges();

                    for(let key in cellChanges) {
                        let newValue = row[key];
                        let field = row._origFields[key];

                        if(key === 'delete') {
                            // only toggle when deletion in spreadsheet is not equal to deletion in store
                            if(!!row.delete !== !!this.reportState.removedRows[ row.id ]) this.$store.dispatch('REPORTS/TOGGLE_REPORT_REMOVE_ROW', {
                                reportId: this.reportId,
                                rowId: row.id
                            });

                            continue;
                        }

                        if(!field) continue;

                        let fieldValueSetter = (fieldValueByEditor[field.editor] || fieldValueByEditor.default).set;

                        fieldsAndValues.push({
                            field,
                            value: fieldValueSetter ? fieldValueSetter(newValue, field) : newValue
                        });
                    }
                }
            });

            this.$store.dispatch('REPORTS/SET_REPORT_ALL_FIELD_VALUES',  { reportId: this.reportId, fieldsAndValues });
        },
        createHeaders(columns){
            let headers = [];
            let thisComponent = this;

            if(this.description.canRemove) {
                headers.push({
                    name:'delete',
                    dataKey:'delete',
                    dataType:'delete',
                    width:50
                });
            }

            if(this.openDetailEnabled || this.description.entityDetailDoExpand) {
                headers.push({
                    name: '',
                    dataKey: 'openDetail',
                    dataType: 'string',
                    width: 90,
                    renderer: SpreadsheetRendererOpenDetail
                });
            }

            const getSortValue = (col) => this.query.sort?.[col.columnName] || col.sort?.direction;
            const getFilterValue = (col) => {
                let value = this.query.filter?.[col.columnName];
                let storedValueTextMap = this.query.filterValueTexts?.[col.columnName] || {};

                // try to recover full value from url query if it was an object before
                if(storedValueTextMap[value]) {
                    value = { text: storedValueTextMap[value], value };
                }

                return value;
            };

            const hasSuggestions = (column) => ['COMBO_BOX','COMBO_BOX_MULTI','BOOLEAN','LIST_VALUE','AUTO_COMPLETE'].includes(column.filter?.editor);
            const isMultiFilter = (column) => ['COMBO_BOX_MULTI'].includes(column.filter?.editor);

            function getFilterSuggester(column){
                if(!column.filter) return;
                if(!hasSuggestions(column)) return;

                const dataUrl = column.filter.filterValuesSearchUrl || column.filter.editorValuesUrl;
                if(!dataUrl) return;

                const responseHasAllValues = !dataUrl.includes('{token}');

                let lastReqId;
                let loadSuggestionsDebounced = object.debounce(function({ prefix, header }, done){
                    if(!prefix) return;
                    lastReqId = backend.reports.editorValues({ url: dataUrl.replace('{token}', encodeURIComponent(prefix)) }, (data, status, headers, rawData, requestId) => {
                        if(requestId === lastReqId) {
                            done(data.values.map(v => ({ text: v.title, value: v.name })));
                        }
                    });
                }, 250);

                return function({ prefix, header }, done){
                    prefix = prefix || '';

                    // suggestions from BE
                    if(!responseHasAllValues) {
                        if(prefix) loadSuggestionsDebounced({ prefix, header }, done);
                        else done([]);
                    }
                    else {
                        // initial values if no BE autocomplete
                        if(!header.lastFilterSuggestions) backend.reports.editorValues({ url: dataUrl }, data => {
                            header.lastFilterSuggestions = data.values.map(v => ({ text: v.title+'', value: v.name+'' }));
                            done(header.lastFilterSuggestions.filter(s => s.text.toString().toLowerCase().includes(prefix.toString().toLowerCase())));
                        });

                        // filtering on FE
                        else if(header.lastFilterSuggestions) {
                            done(header.lastFilterSuggestions.filter(s => s.text.toString().toLowerCase().includes(prefix.toString().toLowerCase())));
                        }
                    }
                };
            }

            if(this.description.hasHierarchy){
                headers.push({
                    name: '',
                    dataKey: 'hasChildren',
                    readonly: true,
                    width: 70,
                    renderer: SpreadsheetRendererExpand,
                    reportId: this.reportId,
                    autoResize: false, // do not change width if table is auto resizing to fit container width
                    // rendererAction: expandVariantsAction - this is implemented directly inside renderer
                });
            }

            // TODO: find better place to handle force-reload filter values
            const onFilterReload = (prevValues) => {
                if(this.reloadFilters){
                    this.$emit('filters-reloaded');
                    return;
                }
                return prevValues;
            };

            headers = headers.concat(columns.map((col, index) => {
                return {
                    reportId: this.reportId, // for backend services used in spreadsheet watchers
                    name: col.columnLabel,
                    dataKey: col.columnName,
                    toggleEdit({ rowIndex, colIndex, row, column, value, setValue }) {
                        let field = row._origFields[ column.dataKey ];
                        let fieldEditor = field.editor;

                        let editorDrawerComponent = drawerComponentByFieldEditor[ fieldEditor ];
                        if(!editorDrawerComponent) return true;
                        thisComponent.editorDrawer.opened = true;
                        thisComponent.editorDrawer.title = column.name;
                        thisComponent.editorDrawer.rowIndex = rowIndex;
                        thisComponent.editorDrawer.colIndex = colIndex;
                        thisComponent.editorDrawer.component = editorDrawerComponent;
                        thisComponent.editorDrawer.value = value;
                        thisComponent.editorDrawer.setValue = setValue;
                        thisComponent.editorDrawer.key = new Date().getTime(); // force refresh editor when selection changed
                    },
                    dataType({ row, column }){
                        let field = row._origFields[ column.dataKey ];
                        return dataTypeByEditor[ field.editor ] || dataTypeByEditor.default;
                    },
                    readonly({ row, column }){
                        // readonly is dynamic, because of row templates
                        let field = row._origFields[ column.dataKey ];
                        let hasNoEditor = [ null, undefined, 'READ_ONLY' ].includes(field.editor);
                        return hasNoEditor ? true : false;
                    },
                    required({ row, column }){
                        let field = row._origFields[ column.dataKey ];
                        return col.required || field.required;
                    },
                    width: col.columnWidth || 150,
                    sortable: !!col.sort,
                    sort: getSortValue(col) === 'ASC' ? 1 : (getSortValue(col) === 'DESC' ? -1 : 0),
                    filterable: !!col.filter,
                    filter: getFilterValue(col),
                    filterMultiple: isMultiFilter(col),
                    filterSuggestions: getFilterSuggester(col),
                    filterIsEnum: hasSuggestions(col),
                    renderer: SpreadsheetRendererDefault,
                    href({ row, column }){

                        function isAbsoluteUrl(url){ return url && url.match(/^http[s]{0,1}:\/\//); }
                        function unifiedUrl(url) {
                            if(!url) return '';
                            else if(isAbsoluteUrl(url)) return url;
                            else return '#' + (url[0] === '/' ? '' : '/') + url;
                        }
                        function cellHasChanged(){
                            return row.hasChanged() && !!row.getCellChanges()[ column.dataKey ];
                        }

                        let href = unifiedUrl(row._origFields[ column.dataKey ]?.url);

                        return href && !cellHasChanged() ? href : null;
                    },
                    info({ row, column, colIndex }){
                        let requestedValue = row._origFields?.[ column.dataKey ]?.requestedValue;
                        return requestedValue ? this.$translate('requested_value') + ': ' + requestedValue : null;
                    },

                    autocompleteDisabled({ column, row, rowIndex, colIndex }){
                        // because not all dataTypes in column are same, need to check on watcher run
                        let dataType = typeof column.dataType === 'function' ? column.dataType.call(this, { column, row, rowIndex, colIndex, rowContext:this.getRowContext(rowIndex) }) : column.dataType;
                        let field = row._origFields[ column.dataKey ];
                        return dataType !== 'textValueObject' || !field.editorValuesUrl;
                    },
                    autocomplete: object.debounce(function({ column, row, prefix, text, value, prevValues }, cb){
                        let field = row._origFields[ column.dataKey ];
                        const responseHasAllValues = !field.editorValuesUrl.includes('{token}');
                        const url = field.editorValuesUrl.replace('{token}', encodeURIComponent(prefix));

                        // cached values
                        if(responseHasAllValues) {
                            if(column.onFilterReload) prevValues = onFilterReload(prevValues);
                            if(prevValues) return cb(prevValues);
                        }

                        backend.genericForms.editorValues({ url }, data => {
                            cb(data.values.map(item => {
                                return {
                                    text: item.title,
                                    value: { text: item.entityName || item.title, value: item.name }
                                };
                            }));
                        });
                    }, 200),

                    // apply all inv global watchers bye default for all columns, because data type is dynamic, and may be different in cells in one column
                    ...Object.entries(globalInvWatchers).map(([key]) => key).reduce((obj, key) => ({ ...obj, [key]:true }), {})
                };
            }));

            if(this.customizeHeaders) this.customizeHeaders(headers);
            return headers;
        },
        transformObjectToRow(obj){
            let rowData = {
                _origFields: {},
                _active: false,
                delete: !!this.reportState.removedRows[ obj.id ], // sync deletion from store
                id: obj.id,
                hasChildren: obj.hasChildren?.value,
                level: obj.level
            };

            obj.fields.forEach(field => {
                // cache field data on row object for future purpose
                rowData._origFields[ field.columnName ] = field;
                
                let fieldValueGetter = (fieldValueByEditor[field.editor] || fieldValueByEditor.default).get;
                rowData[ field.columnName ] = fieldValueGetter ? fieldValueGetter(field) : field.value;
            });

            return rowData;
        },

        onFilter({ header, colIndex, dataKey, value, revert }){
            this.confirmReload(reload => {
                if(!reload) return revert();

                let valueTexts = value?.text ? { [value.value]:value.text } : null;
                if(Array.isArray(value)) {
                    valueTexts = {};
                    value.forEach(v => {
                        if(v.text) valueTexts[v.value] = v.text;
                    });
                }

                this.setFilterValue(dataKey, value, valueTexts); // {13267: 'Testing Group'}
            });
        },
        onSort({ header, colIndex, dataKey, direction, revert }){
            this.confirmReload(reload => {
                if(!reload) return revert();
                
                let reqSort = 'UNORDERED';
                if(direction === 1) reqSort = 'ASC';
                if(direction === -1) reqSort = 'DESC';

                this.$store.dispatch('REPORTS/TOGGLE_REPORT_SORT_DIRECTION', { reportId: this.reportId, colName: dataKey, reqSort });
                this.emitQueryChanged();
            });
        },
        setFilterValue(columnName, value, valueTexts){
            this.$store.dispatch('REPORTS/SET_REPORT_QUERY', { 
                reportId: this.reportId,
                query:{
                    filter:{
                        [columnName]: Array.isArray(value) ? value.map(v => v.value ?? v) : value
                    },
                    filterValueTexts:{
                        ...this.query.filterValueTexts,
                        [columnName]: valueTexts
                    },
                    page: 1
                }
            });
            this.emitQueryChanged();
        },
        setPage(page, revert){
            this.confirmReload(reload => {
                if(!reload) return revert();
                
                this.$store.dispatch('REPORTS/SET_REPORT_QUERY', {
                    reportId: this.reportId,
                    query:{
                        page
                    },
                    reload: true
                });
                this.emitQueryChanged();
            });
        },
        setLimit(limit, revert){
            this.confirmReload(reload => {
                if(!reload) return revert();
                
                this.$store.dispatch('REPORTS/SET_REPORT_QUERY', {
                    reportId: this.reportId,
                    query:{
                        limit: limit,
                        page: 1
                    }
                });
                this.emitQueryChanged();
            });
        },
        
        emitQueryChanged(){
            this.$emit('input', this.query);
        }
    }
}
</script>