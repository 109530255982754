
const DEFAULT_EMPTY_VALUE = null; // undefined;

export default {
    data(){
        return {
            cellsClipboard:[]
        };
    },
    methods:{
        escapeCopiedCellText(text) {
            text = text+'';
            if(text.includes(',') || text.includes('\n') || text.includes('"')) {
                return '"' + text.replace(/"/g, '""') + '"';
            }
            return text;
        },
        // unescapePastedCellText(text = '') {
        //     if (text.startsWith('"') && text.endsWith('"')) {
        //         text = text.substring(1, text.length - 1);
        //         return text.replace(/""/g, '"');
        //     }
        //     return text;
        // },
        parseClipText(text = '') { // parseUnescapePastedText
            // unify new lines
            text = (text+'').replace(/\r\n|\r/g, '\n');

            const rows = [];
            let currentRow = [];
            let currentCell = '';
            let insideQuotes = false;
        
            for (let i = 0; i < text.length; i++) {
                const char = text[i];
                const nextChar = i < text.length - 1 ? text[i + 1] : null;
        
                if (char === '"') {
                    if (insideQuotes && nextChar === '"') { // Handle escaped quote
                        currentCell += char;
                        i++; // Skip next character
                    }
                    else {
                        insideQuotes = !insideQuotes;
                    }
                }
                else if (char === '\t' && !insideQuotes) {
                    currentRow.push(currentCell);
                    currentCell = '';
                }
                else if (char === '\n' && !insideQuotes) {
                    currentRow.push(currentCell);
                    rows.push(currentRow);
                    currentCell = '';
                    currentRow = [];
                }
                else {
                    currentCell += char;
                }
            }
        
            // Add the last cell and row (if not empty) after the loop
            if (currentCell !== '' || currentRow.length > 0) {
                currentRow.push(currentCell);
                rows.push(currentRow);
            }
        
            return rows;
        },
        copySelectedCells(){
            let rowIndexOffset = this.selectedCells.fromRowIndex;
            
            let clipTable = [];
            this.iterateSelectedCells((rowIndex, colIndex) => {
                let row = this.getRow(rowIndex);
                let relRowIndex = rowIndex - rowIndexOffset;
                clipTable[ relRowIndex ] = clipTable[ relRowIndex ] || [];

                let cellValue = this.getCellValue(rowIndex, colIndex);
                let column = this.columns[row.rowType][colIndex];
                let columnDataType = column.dataType;

                if(typeof columnDataType === 'function') {
                    columnDataType = columnDataType.call(this, { row, column, rowIndex, colIndex, value:cellValue, rowContext:this.getRowContext(rowIndex) });
                }

                let dataType = this.columnDataTypes[ columnDataType ] || {};
                let cellRender = column.copy || dataType.copy || column.render || dataType.render;

                if(typeof cellRender === 'function') {
                    cellValue = cellRender.call(this, { row, column, rowIndex, colIndex, value:cellValue, rowContext:this.getRowContext(rowIndex) });
                }

                clipTable[ relRowIndex ].push(this.escapeCopiedCellText(cellValue));
            });
            
            let clipText = clipTable.map(row => row.join('\t')).join('\n');
            this.fillClipboardData(clipText);
        },
        clearSelectedCells(){
            let changes = [];
            this.iterateSelectedCells((rowIndex, colIndex) => {
                let row = this.getRow(rowIndex);
                let cellNullValue;
                let cellClear = (this.columnDataTypes[ this.columns[row.rowType][colIndex].dataType ] || {}).clear || this.columns[row.rowType][colIndex].clear;

                if(typeof cellClear === 'function'){
                    let cellValue = this.getCellValue(rowIndex, colIndex);
                    cellNullValue = cellClear({ value:cellValue, initValue:row._initValue[ (this.getColumn(row.rowType, colIndex) || {}).dataKey ] });
                }
                else {
                    cellNullValue = this.getCellInitValue(rowIndex, colIndex) === '' ? '' : DEFAULT_EMPTY_VALUE;
                }

                changes.push({ rowIndex, colIndex, value:cellNullValue });
            });

            this.bulkSetCellValue(changes);
        },
        
        createTempTextArea(){
            let textareaElm = document.createElement('textarea');
            textareaElm.style.position = 'fixed';
            textareaElm.style.width = '1px';
            textareaElm.style.height = '1px';
            textareaElm.style.top = '0px';
            textareaElm.style.left = '0px';
            textareaElm.style.opacity = '0';
            textareaElm.style.outline = 'none !important';
            textareaElm.style.zIndex = '99999';

            document.body.appendChild(textareaElm);
            return textareaElm;
        },
        catchPasteData(cb){
            navigator.clipboard.readText().then(clipText => {
                cb(this.parseClipText(clipText), clipText);
            })
            .catch(err => {
                // default fallback
                let textareaElm = this.createTempTextArea();
                textareaElm.addEventListener('input', (event) => {
                    let clipText = textareaElm.value;
                    cb(this.parseClipText(clipText), clipText);
                    textareaElm.remove();
                });
                textareaElm.focus();
            });
        },
        fillClipboardData(text){
            navigator.clipboard.writeText(text).then(() => {
                // success, do nothing
            }, err => {
                //default fallback
                let textareaElm = this.createTempTextArea();
                textareaElm.value = text;

                textareaElm.focus();
                textareaElm.select();

                document.execCommand('copy');
                textareaElm.remove();
            });
        },
        readClipboardData(cb){
            navigator.clipboard.readText().then(clipText => {
                cb(this.parseClipText(clipText), clipText);
            }).catch(err => {});
        },

        insertRowsFromClipboard(){
            let rowIndexOffset = this.selectedCells.fromRowIndex;

            this.readClipboardData(clipTable => {
                if(clipTable.length === 0) return;

                this.addRows(rowIndexOffset, clipTable.map(r => { return {}; }));
                this.pasteCopiedCells(clipTable, rowIndexOffset+1, 0);
            });
        },

        pasteCopiedCells(clipTable, rowIndexOffset = this.selectedCells.fromRowIndex, colIndexOffset = Math.max(0, this.selectedCells.fromColIndex)){
            let iterateClipTable = (clipTable, cb) => {
                clipTable.forEach((row, clipRowIndex) => {
                    row.forEach((cellValue, clipCellIndex) => {
                        let rowIndex = clipRowIndex + rowIndexOffset;
                        let colIndex = clipCellIndex + colIndexOffset;

                        cb(rowIndex, colIndex, cellValue);
                    });
                });
            };

            let resizeClipTableIfNeeded = (clipTable) => {
                let selRows = 1 + Math.abs(this.selectedCells.fromRowIndex - this.selectedCells.toRowIndex);
                let selCols = 1 + Math.abs(this.selectedCells.fromColIndex - this.selectedCells.toColIndex);

                let clipRows = clipTable.length;
                let clipCols = clipTable[0] ? clipTable[0].length : 0;

                // copy clipTable, do not modify existing
                if(clipRows < selRows || clipCols < selCols){
                    clipTable = clipTable.map(row => row.slice());
                }

                // need resize rows
                if(clipRows < selRows) {
                    let origClipTable = clipTable.slice();
                    while(clipRows < selRows){
                        for(let i=0;i<origClipTable.length;i++){
                            clipTable.push(origClipTable[i].slice());
                            clipRows++;
                            if(clipRows === selRows) break;
                        }
                    }
                }

                // need resize cols
                if(clipCols < selCols) {
                    clipTable.forEach(row => {
                        let appendCells = [];
                        let index = 0;
                        let origColstLength = row.length;

                        while((origColstLength + appendCells.length) < selCols){
                            appendCells.push(row[index]);
                            index++;
                            if(index >= origColstLength) index = 0;
                        }

                        Array.prototype.push.apply(row, appendCells);
                    });
                }

                return clipTable;
            };

            /*
             * In some cases, pasted data will not be pasted because of inter-cell dependencies, such as changing readonly states, dataType, etc...
             * Therefore, pasting must repeat until two last iterations are not equal - final stable state
             */

            let pasteCells = (clipTable, prevCellStates = {}) => {
                let changes = [];
                let cellStates = [];

                clipTable = resizeClipTableIfNeeded(clipTable);

                iterateClipTable(clipTable, (rowIndex, colIndex, pastedCellValue) => {
                    if(colIndex > this.columns.length-1) return;
                    if(rowIndex > this.rows.length-1) this.addRow();

                    let row = this.getRow(rowIndex);
                    if(!row) return;

                    let prevCellState = prevCellStates[rowIndex + ':' + colIndex];
                    let cellState = {
                        readOnly: this.isCellReadonly(rowIndex, colIndex),
                        editable: this.isCellEditable(rowIndex, colIndex),
                        dataKey: (this.getColumn(row.rowType, colIndex) || {}).dataKey,
                        change: { rowIndex, colIndex, value:pastedCellValue },
                        prevValue: this.getCellValue(rowIndex, colIndex)
                    };
                    cellState.isWritable = cellState.dataKey && cellState.editable && !cellState.readOnly;
                    cellStates[rowIndex + ':' + colIndex] = cellState;
                    

                    // update cell cases
                    // 1. not-readonly, editable, has dataKey
                    if(cellState.isWritable) {
                        if(prevCellState && prevCellState.isWritable){} // change already written in prev iteration
                        else changes.push(cellState.change);
                    }

                    // 2. revert change if cell loses dataKey - in case when changing rowType
                    if(!cellState.dataKey && prevCellState && prevCellState.dataKey) {
                        this.$set(row, prevCellState.dataKey, prevCellState.prevValue); // silent rollback row[dataKey] value
                    }
                });

                // paste is done, there is no additional changes
                if(changes.length === 0) return;

                this.onChangeWatchersDone(() => {
                    pasteCells(clipTable, cellStates);
                });

                this.bulkSetCellValue(changes);
            }

            if(clipTable) pasteCells(clipTable);
            else this.catchPasteData(pasteCells);
        },
    }
};