<template>
    <div>
        <div    class="cell-editor"
                :class="{ inline: useInlineEdit }"
                :style="{ 'min-width': (minWidth - 10) + 'px' }"
                ref="editor" 
                @mousedown="$event.stopPropagation()">
            <div    class="cell-editor-content"
                    ref="editorContent"
                    contenteditable="true"
                    @input="onInput"
                    @keydown.escape="$event.preventDefault();editingDone(true)"
                    @keydown="focusAutocompleteItem"
                    @blur="suggestions.length ? null : editingDone()" 
                    :placeholder="placeholder | translate">
            </div>
        </div>
        <div v-if="suggestions.length" 
             class="cell-editor-autocomplete" 
             :class="{ top:suggPosition.above }"
             :style="{ top:suggPosition.top+'px', left:suggPosition.left+'px' }"
             @mousedown="$event.stopPropagation()">

            <div    v-for="(sugg,i) in suggestions" 
                    :key="i" 
                    class="cell-editor-autocomplete-item" 
                    :class="{ focused:suggPosition.focusedIndex === i }" 
                    @mousedown="$event.preventDefault();$event.stopPropagation();setSuggestedValue(sugg)">
                
                <component  v-if="suggRenderer" 
                            :is="suggRenderer"
                            :text="sugg.text"
                            :value="sugg.value"
                            :data-types="dataTypes"
                            :data-type-id="dataTypeId"
                            :row="row"
                            :column="column"
                            :row-index="rowIndex"
                            :col-index="colIndex"
                            :get-spreadsheet-component="getSpreadsheetComponent">
                </component>
                <template v-else>{{sugg.text}}</template>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .cell-editor {
        background-color: #fff;
        z-index:1;
    }

    .cell-editor.inline {
        position: absolute;
        top:1px;
        bottom:0px;
        left:0px;
        padding: 4px 4px 0px 4px;
    }

    .cell-editor-content {
        display: inline;
    }

    .cell-editor-content:active,
    .cell-editor-content:focus {
        outline: none;
    }

    .cell-editor-content[placeholder]:empty::before {
        content: attr(placeholder);
        color: #bebebe; 
    }

    .cell-editor-content {
        background-color: #fff !important;
    }

    /* .cell-editor[placeholder]:empty:focus::before {
        content: "";
    } */

    .cell-editor-autocomplete {
        position: fixed;
        background-color: #fff;
        border: 1px solid #ccc;
        z-index: 4;
        min-width: 98px;
        margin-left:-1px;
        box-shadow: 4px 4px 6px -3px rgba(0,0,0,0.75);
        max-height: 202px;
        overflow-x: auto;
    }

    .cell-editor-autocomplete.top {
        box-shadow: 4px -4px 6px -3px rgba(0,0,0,0.75);
    }

    .cell-editor-autocomplete-item {
        height: 20px;
        padding: 2px 4px;
        cursor: pointer;
    }

    .cell-editor-autocomplete-item:not(:first-child) {
        border-top: 1px solid #ccc;
    }

    .cell-editor-autocomplete-item:hover {
        background-color: #f3f3f3;
    }

    .cell-editor-autocomplete-item.focused {
        background-color: #f3f3f3;
    }

</style>

<script>
    import dataTypes from './spreadsheet-data-types.js';
    import isEmptyValue from './is-empty-value';

    // TODO: update obj-fe utils
    String.prototype.removeDiacritics = String.prototype.removeDiacritics || function(){ return this.toLowerCase(); };

    export default {
        props:{
            value: {},
            cellValue:{},
            minWidth:{
                type: Number,
                default: 100
            },
            valueChanged: Boolean,
            rowIndex: Number,
            colIndex: Number,
            row:{},
            column:{},
            dataTypes:{},
            dataTypeId:{},
            getSpreadsheetComponent: Function,
            getViewValue: Function
        },
        data(){
            return {
                innerValue: '',
                changed: false,
                suggestions:[],
                suggPosition:{
                    focusedIndex: -1,
                    top:0,
                    left:0,
                    above: false
                },
                placeholder: ''
            };
        },
        watch:{
            value:{
                immediate:true,
                handler(value){
                    this.updateEditorValue(value);
                }
            }
        },
        computed:{
            suggHeight(){
                return Math.min(1 + (21*this.suggestions.length), 202);
            },
            suggRenderer(){
                return this.column.editorSuggestionRenderer;
            },
            useInlineEdit(){
                return this.getSpreadsheetComponent().virtualScrollOn;
            }
        },
        methods:{
            translateValue(v){
                if(this.column.translateValue) return this.$translate(v + '');
                else return v;
            },
            getOpContext(){
                return {
                    row: this.row,
                    column: this.column,
                    rowIndex: this.rowIndex,
                    colIndex: this.colIndex,
                    rowContext: this.getSpreadsheetComponent().getRowContext(this.rowIndex),
                };
            },
            getDataType(){
                let opContext = this.getOpContext();
                let dataTypeId = this.column.dataType;
                if(typeof this.column.dataType === 'function') dataTypeId = this.column.dataType.call(this.getSpreadsheetComponent(), { ...opContext });
                return dataTypes[ dataTypeId ] || {};
            },
            autocomplete(){
                let opContext = this.getOpContext();
                let dataType = this.getDataType();
                let inputText = (this.innerValue || '') + '';
                let value = dataType.parse ? dataType.parse.call(this.getSpreadsheetComponent(), { ...opContext, value:inputText, suggestMode:true }) : inputText;
                let prefix = (Array.isArray(value) ? value[value.length-1] : inputText).trimStart().removeDiacritics();

                let values;

                let autocompleteDisabled = this.column.autocompleteDisabled || false;
                if(typeof this.column.autocompleteDisabled === 'function') {
                    autocompleteDisabled = this.column.autocompleteDisabled.call(this.getSpreadsheetComponent(), { ...opContext });
                }

                if(!autocompleteDisabled && this.column.autocomplete) {
                    values = this.column.autocomplete.call(this.getSpreadsheetComponent(), {
                        ...opContext,
                        prefix,
                        text: inputText,
                        value,
                        prevValues: this.autocompleteCalled ? this.prevValues : null
                    }, (values, dontFilter) => {
                        this.prevValues = values;

                        if(dontFilter) this.suggestions = values.map(s => {
                            let isSuggString = typeof s === 'string';
                            
                            return {
                                text: this.translateValue(isSuggString ? s : s.text),
                                value: isSuggString ? s : s.value
                            };
                        });
                        else this.suggestions = this.filterUnifySuggestions(prefix, values);

                        if(this.suggestions.length > 0) this.calcSuggPosition();
                    });

                    this.autocompleteCalled = true;

                    // autocomplete can cache prev suggestions if needed, and let them ct like values
                    if(!values) return;
                }

                this.suggestions = this.filterUnifySuggestions(prefix, values || this.column.values || dataType.values || []);

                if(this.suggestions.length > 0) this.calcSuggPosition();
            },
            filterUnifySuggestions(prefix, values){
                let opContext = this.getOpContext();
                let inputText = (this.innerValue || '') + '';
                if(typeof values === 'function') values = values.call(this.getSpreadsheetComponent(), { ...opContext });
                if(!Array.isArray(values) || values.length === 0) return [];

                let someValueExactMatched = false;
                let suggs = values.filter(value => !isEmptyValue(value)).map(value => {
                    let hasText = typeof value.text === 'string';
                    let text = hasText ? value.text : value+'';
                    if(inputText === this.translateValue(text)) someValueExactMatched = true;
                    return hasText ? value : { text, value };
                });
                
                if(prefix && !this.getDataType().values) {
                    if(!someValueExactMatched) suggs = suggs.filter(({ text, value }) => this.translateValue(text).removeDiacritics().indexOf(prefix) > -1);
                    suggs.sort((a,b) => {
                        let aStartsWith = this.translateValue(a.text).removeDiacritics().indexOf(prefix) === 0;
                        let bStartsWith = this.translateValue(b.text).removeDiacritics().indexOf(prefix) === 0;

                        if(aStartsWith === bStartsWith) return 0;
                        else return aStartsWith ? -1 : 1;
                    });
                }

                return suggs.map(s => {
                    if(s.text) return { ...s, text:this.translateValue(s.text), value:s.value };
                    else return { text:this.translateValue(s), value:s };
                });
            },
            calcSuggPosition(){
                this.$nextTick(() => {
                    let editorElm = this.$refs.editor;
                    if(!editorElm) return;

                    let editorRect = editorElm.getBoundingClientRect();
                    let top = editorRect.bottom;

                    let above = false;
                    if((top + this.suggHeight) > window.innerHeight){
                        above = true;
                        top = editorRect.top - this.suggHeight;
                    }

                    this.suggPosition = { above, top, left:editorRect.left - this.getOffsetLeft(), focusedIndex:-1 };
                });
            },
            getOffsetLeft(){
                let containerLeft = 0;
                let parent = this.$refs.editor.parentElement;
                while(parent) {
                    if(parent === window.document.body) {
                        containerLeft = window.document.body.getBoundingClientRect().left;
                        break;
                    }
                    else if(!parent.offsetParent) {
                        containerLeft = parent.getBoundingClientRect().left;
                        break;
                    }
                    parent = parent.parentElement;
                }
                return containerLeft;
            },
            focusAutocompleteItem(event){
                if(event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) return;

                if(event.code === 'ArrowDown' && this.suggestions.length > 0) {
                    event.preventDefault();
                    if(this.suggPosition.above) {
                        if(this.suggPosition.focusedIndex === this.suggestions.length-1) this.suggPosition.focusedIndex = -1;
                        else if(this.suggPosition.focusedIndex !== -1) this.suggPosition.focusedIndex++;
                    }
                    else if(this.suggPosition.focusedIndex < this.suggestions.length-1) this.suggPosition.focusedIndex++;
                }
                else if(event.code === 'ArrowUp' && this.suggestions.length > 0) {
                    event.preventDefault();
                    if(this.suggPosition.above) {
                        if(this.suggPosition.focusedIndex === -1) this.suggPosition.focusedIndex = this.suggestions.length-1;
                        else if(this.suggPosition.focusedIndex > 0) this.suggPosition.focusedIndex--;
                    }
                    else if(this.suggPosition.focusedIndex >= 0) this.suggPosition.focusedIndex--;
                }
                else if(event.code === 'Enter') {
                    event.preventDefault();
                    if(this.suggestions.length === 0) this.editingDone();
                    else if(this.suggPosition.focusedIndex > -1) {
                        let targetSugg = this.suggestions[ this.suggPosition.focusedIndex ];
                        if(targetSugg.continueEditing) event.stopPropagation();
                        this.setSuggestedValue(targetSugg);
                    }
                    else this.editingDone();
                }
                else if(event.code === 'Tab') {
                    event.preventDefault();
                    if(this.suggestions.length === 0) this.editingDone();
                    else if(this.suggPosition.focusedIndex > -1) this.setSuggestedValue(this.suggestions[ this.suggPosition.focusedIndex ], false);
                    else this.editingDone();
                }
            },

            getDataType(){
                let opContext = {
                    row: this.row,
                    column: this.column,
                    rowIndex: this.rowIndex,
                    colIndex: this.colIndex,
                    rowContext: this.getSpreadsheetComponent().getRowContext(this.rowIndex),
                };

                let dataTypeId = this.column.dataType;
                if(typeof this.column.dataType === 'function') dataTypeId = this.column.dataType.call(this.getSpreadsheetComponent(), { ...opContext });
                return dataTypes[ dataTypeId ] || {};
            },

            setSuggestedValue(suggestion, dontEndEditing){ // setting/appending text value into text input
                let opContext = {
                    row: this.row,
                    column: this.column,
                    rowIndex: this.rowIndex,
                    colIndex: this.colIndex,
                    rowContext: this.getSpreadsheetComponent().getRowContext(this.rowIndex),
                };

                dontEndEditing = dontEndEditing !== undefined ? dontEndEditing : suggestion.continueEditing;
                
                let dataType = this.getDataType();
                let onSeclect = (cb) => cb();

                (suggestion.onSelect || onSeclect)((val = suggestion.value) => {
                    if(dataType.multi){
                        // dontEndEditing = true;
                        val = val ? [val] : val;

                        let inputText = (this.innerValue || '') + '';
                        let currValue = dataType.parse ? dataType.parse.call(this.getSpreadsheetComponent(), { ...opContext, value:inputText, suggestMode:true }) : inputText;
                        let newValue = dataType.parse ? dataType.parse.call(this.getSpreadsheetComponent(), { ...opContext, value:val, suggestMode:true }) : val;
                        newValue = (currValue || []).slice(0, -1).concat(newValue || []); //.concat(['']);

                        this.innerValue = dataType.render ? dataType.render.call(this.getSpreadsheetComponent(), { ...opContext, value:newValue }) : (newValue + '');
                    }
                    else this.innerValue = val;

                    this.changed = true;
                    let text = val && val.text ? val.text : val;
                    this.setEditorText(text ?? this.innerValue);

                    if(dontEndEditing) {
                        this.focus();
                        this.autocomplete();
                    }
                    else this.editingDone();
                });
            },
            unifyValue(value){
                // return typeof value === 'string' ? value.replace(/\n|\r\n?/g, '\n') : value;
                return value; // do not strip white spaces
            },
            onInput() {
                if(!this.$refs.editorContent) return;
                this.changed = true;
                this.innerValue = this.$refs.editorContent.innerText;
                this.$emit('input', this.unifyValue(this.innerValue));
                this.autocomplete();
            },
            editingDone(isReset){
                if(isReset) {
                    this.changed = false;
                    return this.$nextTick(() => this.$emit('reset'));
                }
                else if(!this.changed) return;

                this.changed = false;
                this.$emit('change', this.unifyValue(this.innerValue));
                setTimeout(() => this.$emit('close'));
            },
            setEditorText(text){
                if(this.$refs.editorContent) this.$refs.editorContent.innerText = text;
            },
            updateEditorValue(value){ // changed value from outside of component
                if(!this.$refs.editorContent) return;
                value = this.cellValue === value ? this.row._state.cellViewValues[ this.colIndex ] : value;
                if(isEmptyValue(value)) value = '';

                if(this.innerValue === value) return value === '' ? this.autocomplete() : null;

                this.changed = this.valueChanged;
                this.innerValue = value;
                this.setEditorText(value);

                this.autocomplete();
            },
            focus(){
                let editorElm = this.$refs.editorContent;
                if(!editorElm) return;

                editorElm.focus();

                let textNode = editorElm.lastChild,
                    caret = textNode ? textNode.length : 0,
                    range = document.createRange(),
                    sel = window.getSelection();

                range.setStart(textNode || editorElm, caret);
                range.setEnd(textNode || editorElm, caret);

                sel.removeAllRanges();
                sel.addRange(range);
            }
        },
        mounted(){
            this.$nextTick(() => {
                this.placeholder = this.getDataType().placeholder;
                this.updateEditorValue(this.value);
                this.focus();
            });
        }
    };
</script>