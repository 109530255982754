<template>
  <inv-object-tab-item :tab="tab" :tabIsLoading="tabIsLoading || dataIsLoading">
    <template v-if="expanded && tab" slot="header">
      <div class="inv-object-button-group" :style="selected ? 'padding-right: 480px' : 0">

        <v-tooltip v-if="tab.parameters.sourceUrl" top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="bigPictureDslEditorOpen()">
              <v-icon>fal fa-cog</v-icon>
            </v-btn>
          </template>
          <span>
            {{ "edit_big_picture_dsl" | translate }}
          </span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="isProfilesOpen = !isProfilesOpen" :color="isProfilesOpen ? 'accent' : ''">
              <v-icon>fal fa-list-check</v-icon>
            </v-btn>
          </template>
          <span>
            {{ "profiles" | translate }}
          </span>
        </v-tooltip>

        <v-divider vertical style="margin: 0 8px 0 16px" />

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="fetchMetadata">
              <v-icon>fal fa-redo</v-icon>
            </v-btn>
          </template>
          <span>
            {{ "reload" | translate }}
          </span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="toggleKeepSize" :color="keepSize ? 'accent' : ''">
              <v-icon>fal fa-expand-arrows</v-icon>
            </v-btn>
          </template>
          <span>
            {{ "fit_svg" | translate }}
          </span>
        </v-tooltip>

        <v-divider vertical style="margin: 0 8px" />

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              :to="{ name: 'inventory-object', params: { id: tab.parameters.diagramId } }"
              target="_blank"
              icon
              exact
              v-on="on"
            >
              <v-icon>fal fa-link</v-icon>
            </v-btn>
          </template>
          <span>
            {{ "diagram_source_object" | translate }}
          </span>
        </v-tooltip>
      </div>
    </template>
    <template slot="body">
      <div
        ref="svgContainer"
        :id="'svg-container-' + tab.parameters.dataSource"
        class="svg-container"
        :class="{ 'tab-body-disabled': tabIsLoading || dataIsLoading, 'svg-container__panning': isPanning }"
        @click.prevent
        @contextmenu="handleContextMenu"
        @mouseup.capture="handleMouseUp"
      >
        <SvgPanZoom
          :key="metadata ? metadata.svgId : ''"
          v-if="svgContent !== ''"
          :zoomEnabled="true"
          :dblClickZoomEnabled="false"
          :fit="false"
          :center="true"
          :panEnabled="true"
          :minZoom="0.0001"
          :zoomScaleSensitivity="0.5"
          :maxZoom="1000"
          @svgpanzoom="registerSvgPanZoom"
          :on-pan="handlePan"
          :on-zoom="handleZoom"
        >
          <svg ref="svgDiagram" id="real-svg" v-html="svgContent" />
          <svg v-if="browserSupported !== 'firefox'" slot="thumbnail" v-html="svgContent" style="background: white" />
        </SvgPanZoom>
        <v-menu v-model="contextMenuShown" :position-x="contextMenu.x" :position-y="contextMenu.y" absolute offset-y>
          <v-list>
            <v-list-item @click="selected = contextMenu.objectId">
              <v-list-item-icon>
                <v-icon>fal fa-address-card</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Inspect in drawer</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'inventory-object', params: { id: contextMenu.objectId } }" target="_blank" exact>
              <v-list-item-icon>
                <v-icon>fal fa-external-link-alt</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Open in new tab</v-list-item-title>
            </v-list-item>

            <template v-if="contextMenu.node">
              <v-divider />
              <v-list-item
                v-for="nextHop in getNextHopRelationsForType(contextMenu.node.type)"
                :key="nextHop.nextHopId"
                @click="toggleNextHop(nextHop)"
              >
                <v-list-item-icon>
                  <v-icon>fal fa-diagram-nested</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ nextHop.name }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon v-if="isNextHopSelected(nextHop)">
                  <v-icon>fal fa-check</v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-divider />

              <v-list-item @click="toggleBlackListNode(contextMenu.node)">
                <v-list-item-icon>
                  <v-icon color="error">fal fa-ban</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ "hide" | translate }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-if="!contextMenu.node && contextMenu.relation">
              <v-divider />
              <v-list-item @click="toggleBlackListRelation(contextMenu.relation)">
                <v-list-item-icon>
                  <v-icon color="error">fal fa-ban</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ "hide" | translate }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </div>
      <v-navigation-drawer
        floating
        :value="!!selected"
        class="object-side-drawer"
        absolute
        right
        clipped
        z-index="1"
        :width="!!selected ? '480' : 0"
        :style="!!selected ? 'padding: 8px' : 0"
      >
        <inv-mini-object v-if="selected" :id="selected" @close="selected = null" />
      </v-navigation-drawer>

      <v-navigation-drawer
        scrim
        floating
        class="object-side-drawer"
        absolute
        left
        z-index="1"
        :value="!!isProfilesOpen"
      >
        <v-container>
          <h3 class="text-h3">
            {{ (metadata || {}).profiles ? "profiles" : "no_profiles" | translate }}
          </h3>

          <v-row no-gutters v-for="profile in (metadata || {}).profiles" :key="profile.profileId">
            <v-col>
              <v-checkbox
                v-model="selectedProfiles"
                :label="profile.name"
                :value="profile.profileId"
                hide-details
                color="accent"
              />
            </v-col>
          </v-row>

          <template>
            <h3 class="text-h3 mt-6">
              {{ blackListNodes.size ? "black_list_nodes" : "no_black_list_nodes" | translate }}
            </h3>

            <v-list>
              <v-list-item
                v-for="node in blackListNodes.values()"
                :key="node.nodeId"
                @click="toggleBlackListNode(node)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ node.name }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon color="success">fal fa-circle-check</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </template>

          <template>
            <h3 class="text-h3 mt-6">
              {{ blackListRelations.size ? "black_list_relations" : "no_black_list_relations" | translate }}
            </h3>

            <v-list>
              <v-list-item
                v-for="relation in blackListRelations.values()"
                :key="relation.relationId"
                @click="toggleBlackListRelation(relation)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ relation.name }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon color="success">fal fa-circle-check</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </template>
        </v-container>
      </v-navigation-drawer>

      <v-navigation-drawer 
          v-model="bigPictureDslEditor.opened" 
          floating
          class="object-side-drawer"
          absolute
          right
          clipped
          z-index="1"
          :width="bigPictureDrawerWidth"
          :style="'padding: 0px'"
        >
            <v-card flat class="fill-height">
                <v-card-title style="padding:6px">
                    <v-subheader>{{'big_picture_dsl_editor' | translate}}</v-subheader>

                    <v-spacer></v-spacer>

                    <InvCustomReportButtons
                        :buttons="bigPictureDslEditor.buttons"
                        @clicked="button => bigPictureDslEditor.actionButton = { ...button }"
                    />
                    
                    <InvReportActions
                        :button="bigPictureDslEditor.actionButton"
                        @closed="bigPictureDslEditor.actionButton = null"
                    />

                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-btn 
                                icon
                                v-on="on"
                                :disabled="bigPictureDslEditor.source === bigPictureDslEditor.updatedSource"
                                :outlined="bigPictureDslEditor.source !== bigPictureDslEditor.updatedSource"
                                @click="bigPictureDslEditorSave()"
                                color="success" 
                            >
                                <v-icon>fal fa-save</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{'save'|translate}}
                        </span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="bigPictureDslEditor.opened = false"
                                v-on="on"
                                icon
                                outlined
                                class="object-close-button" 
                                data-test="close-button"
                            >
                                <v-icon>fal fa-arrow-right</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{'close' | translate}}
                        </span>
                    </v-tooltip>
                </v-card-title>

                <v-card-text style="height:calc(100% - 48px)">
                  <InvBpdslEditor
                      :value="bigPictureDslEditor.source"
                      @input-text="value => bigPictureDslEditor.updatedSource = value"
                      :placeholder="'enter_big_picture_code_here' | translate"
                      @valid="isValid => bigPictureDslEditor.isValid = isValid"
                      auto-height 
                      @parser-result="result => bigPictureDslEditor.parserResult = result"
                      :dslDefinition="{ nodes:[] }">
                  </InvBpdslEditor>
                </v-card-text>
            </v-card>
        </v-navigation-drawer>

    </template>

  </inv-object-tab-item>
</template>

<script>
import backend from "obj-fe/app/backend";
import SvgPanZoom from "vue-svg-pan-zoom";

import tabBehaviour from "./inv-object-tab-behaviour";
import InvObjectTabItem from "./inv-object-tab-item.vue";

import InvBpdslEditor from '../../dsl/bpdsl/inv-bpdsl-editor.vue';
import InvCustomReportButtons from '../../custom-reports/inv-custom-report-buttons.vue';
import InvReportActions from '../../custom-reports/inv-report-actions.vue';

function backendDslBigPictureLazyCachedSvgUrl(url) {
  return new Promise((resolve, reject) => {
    backend.dsl.bigPictureLazyCachedSvgUrl(
      { customRestUrl: url },
      (data) => resolve(data),
      (err) => reject(err)
    );
  });
}

function backendDslBigPictureLazyMetadataUrl(url, profiles, { nextHopRequests, blackListNodes, blackListRelations }) {
  return new Promise((resolve, reject) => {
    backend.dsl.bigPictureLazyMetadataUrl(
      { customRestUrl: url, profiles, nextHopRequests, blackListNodes, blackListRelations },
      (data) => resolve(data),
      (err) => reject(err)
    );
  });
}

export default {
  mixins: [tabBehaviour],
  components: {
    InvObjectTabItem,
    SvgPanZoom,
    InvBpdslEditor,
    InvCustomReportButtons,
    InvReportActions
  },
  data() {
    return {
      svgPanObject: null,
      svgContent: "",
      selected: null,
      contextMenuShown: false,
      contextMenu: {
        node: null,
        object: null,
        x: 0,
        y: 0,
      },
      metadata: null,
      selectedProfiles: [],
      selectedNextHops: new Map(),
      blackListNodes: new Map(),
      blackListRelations: new Map(),
      dataIsLoading: false,
      isProfilesOpen: false,
      isPanning: false,
      keepSize: true,
      svgContainerResizeObserver: null,

      bigPictureDslEditor: {
        opened:false,
        source:'',
        updatedSource:'',
        actionButton: null,
        buttons:[
          {
              "icon": "fas fa-info-circle",
              "buttonDisplayString": null,
              "displayString": "BigQuery syntax",
              "title": null,
              "toolTip": null,
              "restUrl": "rest/dsl/bigQueryUsageHelp",
              "fePlacement": "MORE_ACTIONS",
              "feType": "SHOW_HTML_BUTTON"
          },
          {
              "icon": "fas fa-chart-network",
              "buttonDisplayString": null,
              "displayString": "BigQuery Nodes",
              "title": null,
              "toolTip": null,
              "restUrl": "rest/dsl/bigQueryNodesHelp",
              "fePlacement": "MORE_ACTIONS",
              "feType": "SHOW_HTML_BUTTON"
          },
          {
              "icon": "fas fa-arrow-alt-right",
              "buttonDisplayString": null,
              "displayString": "BigQuery Relations",
              "title": null,
              "toolTip": null,
              "restUrl": "rest/dsl/bigQueryRelationsHelp",
              "fePlacement": "MORE_ACTIONS",
              "feType": "SHOW_HTML_BUTTON"
          },
          {
              "icon": "fas fa-palette",
              "buttonDisplayString": null,
              "displayString": "BigPicture Renderers",
              "title": null,
              "toolTip": null,
              "restUrl": "rest/dsl/bigQueryRenderersHelp",
              "fePlacement": "MORE_ACTIONS",
              "feType": "SHOW_HTML_BUTTON"
          },
          {
              "icon": "fa-chart-network",
              "buttonDisplayString": null,
              "displayString": "EMDSL Nodes",
              "title": null,
              "toolTip": null,
              "restUrl": "rest/dsl/emdslNodesHelp",
              "fePlacement": "MORE_ACTIONS",
              "feType": "SHOW_HTML_BUTTON"
          },
          {
              "icon": "fa-arrow-alt-right",
              "buttonDisplayString": null,
              "displayString": "EMDSL Relations",
              "title": null,
              "toolTip": null,
              "restUrl": "rest/dsl/emdslRelationsHelp",
              "fePlacement": "MORE_ACTIONS",
              "feType": "SHOW_HTML_BUTTON"
          },
          {
              "icon": "fas fa-turtle",
              "buttonDisplayString": null,
              "displayString": "BigQuery LazyLoading syntax",
              "title": null,
              "toolTip": null,
              "restUrl": "rest/dsl/bigQueryUsageHelpLazyLoading",
              "fePlacement": "MORE_ACTIONS",
              "feType": "SHOW_HTML_BUTTON"
          }
        ],
      }
    };
  },
  computed: {
    browserSupported() {
      let userAgent = navigator.userAgent;
      let browserName;

      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "safari";
      } else if (userAgent.match(/opr\//i)) {
        browserName = "opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "edge";
      } else {
        browserName = "No browser detected";
      }
      return browserName;
    },
    bigPictureDrawerWidth(){
      return Math.min(window.innerWidth / 2.5, 1600);
    }
  },
  watch: {
    selected: function (selected) {
      if (selected)
        this.$store.dispatch("INVENTORY/LOAD_OBJECT_DETAIL_PREVIEW", {
          objectId: selected,
          errorCb: () => {
            this.selected = null;
          },
        });
      this.syncSVGDataProps();
    },
    svgPanObject: {
      immediate: true,
      handler(newSvgPanObject) {
        if (newSvgPanObject) {
          if (this.svgContainerResizeObserver) this.svgContainerResizeObserver.disconnect();
          this.svgContainerResizeObserver = new ResizeObserver(this.fitWhenKeepSize);
          this.svgContainerResizeObserver.observe(this.$refs.svgContainer);
          this.fitWhenKeepSize();
        }
      },
    },
    selectedProfiles() {
      this.fetchMetadata();
    },
  },
  methods: {
    bigPictureDslEditorOpen(){
      this.bigPictureDslEditor.source = '';
      this.bigPictureDslEditor.updatedSource = '';

      backend.dsl.getBigPictureDsl({ url: this.tab.parameters.sourceUrl }, (data) => {
        this.bigPictureDslEditor.opened = true;
        this.bigPictureDslEditor.source = data.source;
        this.bigPictureDslEditor.updatedSource = data.source;
      });
    },
    bigPictureDslEditorSave(){
      backend.dsl.updateBigPictureDsl({ url: this.tab.parameters.sourceUrl }, this.bigPictureDslEditor.updatedSource, (data) => {
        this.fetchMetadata();
        this.bigPictureDslEditor.source = this.bigPictureDslEditor.updatedSource;
      });
    },

    handlePan() {
      this.isPanning = true;
      this.contextMenuShown = false;
    },
    handleZoom() {
      this.contextMenuShown = false;
    },
    getNextHopRelationsForType(type) {
      return this.metadata?.nextHopRelations?.filter((nextHop) => nextHop?.startingTypes?.includes?.(type));
    },
    isNextHopSelected(nextHop) {
      const key = `${this.contextMenu.node.nodeId}@${nextHop.nextHopId}`;
      return this.selectedNextHops.has(key);
    },
    toggleNextHop(nextHop) {
      const key = `${this.contextMenu.node.nodeId}@${nextHop.nextHopId}`;

      if (this.selectedNextHops.has(key)) {
        this.selectedNextHops.delete(key);
      } else {
        this.selectedNextHops.set(key, { nextHopId: nextHop.nextHopId, nodeId: this.contextMenu.node.nodeId, name: nextHop.name });
      }

      this.fetchMetadata();
    },
    toggleBlackListNode(node) {
      const key = node.nodeId;

      if (this.blackListNodes.has(key)) {
        this.blackListNodes.delete(key);
      } else {
        this.blackListNodes.set(key, node);
      }

      this.fetchMetadata();
    },
    toggleBlackListRelation(relation) {
      const key = relation.relationId;

      if (this.blackListRelations.has(key)) {
        this.blackListRelations.delete(key);
      } else {
        this.blackListRelations.set(key, relation);
      }

      this.fetchMetadata();
    },
    async fetchMetadata() {
      this.dataIsLoading = true;

      try {
        const bigPictureLazyMetadataUrl = String(this.tab.parameters.metadataUrl).slice(5);

        const metadata = await backendDslBigPictureLazyMetadataUrl(bigPictureLazyMetadataUrl, this.selectedProfiles, {
          nextHopRequests: [...this.selectedNextHops.values()],
          blackListNodes: [...this.blackListNodes.keys()],
          blackListRelations: [...this.blackListRelations.keys()],
        });

        const bigPictureLazyCachedSvgUrl = String(this.tab.parameters.cachedSvgUrl)
          .slice(5)
          .replace("{svgId}", metadata?.svgId);

        const svg = await backendDslBigPictureLazyCachedSvgUrl(bigPictureLazyCachedSvgUrl);
        this.metadata = metadata;
        this.svgContent = svg;
        this.selected = null;
        this.syncSVGDataProps(true);
      } finally {
        this.dataIsLoading = false;
      }
    },
    onFirstTimeExpandLoad(successCb, errorCb) {
      this.fetchMetadata()
        .catch(() => {
          errorCb();
        })
        .then(() => {
          // Open profiles by default when available on first load
          this.$nextTick(() => {
            if (this.metadata && this.metadata.profiles) {
              this.isProfilesOpen = true;
            }
          });
          successCb();
        });
    },
    handleContextMenu(e) {
      e.preventDefault();
      e.stopPropagation();

      // context menu invoked while still open
      if (this.contextMenuShown) this.contextMenu.object = null;

      let sourceElement = e.srcElement,
        objectId = sourceElement.getAttribute("xlink:href");

      while (!sourceElement.classList.contains("svg-container")) {
        objectId = sourceElement.getAttribute("xlink:href");

        if (objectId) break;
        else sourceElement = sourceElement.parentElement;
      }

      if (!!objectId) {
        const globalId = objectId.split("\/").pop();
        this.contextMenu.objectId = globalId;

        this.contextMenu.x = e.clientX;
        this.contextMenu.y = e.clientY;

        const globalIdFilterFn = ({ globalId: gId }) => gId && gId === globalId;

        this.contextMenu.node = this.metadata?.nodes?.filter?.(globalIdFilterFn)?.[0];
        this.contextMenu.relation = this.metadata?.relations?.filter?.(globalIdFilterFn)?.[0];

        this.$nextTick(() => {
          this.contextMenuShown = true;
        });
      }
    },
    parseObjectIdFromLinkValue(xmlinkHrefValue){
      return (xmlinkHrefValue || '').split('\/').pop();
    },
    syncSVGDataProps(markAllNodes){
      this.$nextTick(() => requestAnimationFrame(() => {

        const svgDiagramElm = this.$refs.svgDiagram;
        if(!svgDiagramElm) return;
        
        if(markAllNodes) {
          const links = Array.from(svgDiagramElm.querySelectorAll('a') || []);
          links.forEach(a => {
            let objectId = this.parseObjectIdFromLinkValue(a.getAttribute('xlink:href'));

            if(objectId) {
              a.dataset.objectId = objectId;

              let isNode = this.metadata?.nodes?.find(n => n.globalId === objectId);
              let isRelation = this.metadata?.relations?.find(r => r.globalId === objectId);

              if(isNode) a.dataset.objectType = 'node';
              else if(isRelation) a.dataset.objectType = 'relation';
            }
          });
        }

        // sync selected
        function toggleSelect(a, isSelected){
          a.dataset.selected = !!isSelected;
        }

        svgDiagramElm.querySelectorAll('[data-selected="true"]').forEach(a => toggleSelect(a, false));

        if(this.selected){
          svgDiagramElm.querySelectorAll('[data-object-id="'+this.selected+'"]').forEach(a => toggleSelect(a, true));
        }

        // sync hops
        svgDiagramElm.querySelectorAll('[data-hops]').forEach(a => {
          a.dataset.hops = null;
          a.setAttribute('xlink:title', a.getAttribute('title'));
        });
        svgDiagramElm.querySelectorAll('[data-expand-icon]').forEach(i => i.remove());

        let hopsByNodeId = {};
        this.selectedNextHops.forEach((hop) => { 
          hopsByNodeId[hop.nodeId] = hopsByNodeId[hop.nodeId] || [];
          hopsByNodeId[hop.nodeId].push({ name:hop.name, id: hop.nextHopId });
        });

        Object.entries(hopsByNodeId).forEach(([ nodeId, hops ]) => {
          svgDiagramElm.querySelectorAll('[data-object-id="'+nodeId+'"]').forEach(elm => {
              elm.dataset.hops = hops.map(h => h.id).join(' ');

              let title = elm.getAttribute('title') || elm.getAttribute('xlink:title');
              elm.setAttribute('title', title);
              elm.setAttribute('xlink:title', title + '\n\n' + hops.map(h => h.name + ' ✓').join('\n'));

              let firstText = elm.querySelector('text');
              if(firstText){
                let tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
                tspan.setAttribute('data-expand-icon', 'true');
                tspan.setAttribute('dx', '-3');
                tspan.setAttribute('dy', '-8');
                tspan.innerHTML = '&#xf058;';
                firstText.appendChild(tspan);
              }
          });
        });
        // is you need to style, use this:
        // [data-hops~="CONNECTEDRESOURCES"]
        // possible hops = "CONNECTEDRESOURCES", "DECOMPOSITION", "PORTS"
      }));
    },
    handleMouseUp(e) {
      if(e.button === 2) return; // mouse right button mouseup - after contextmenu click
      
      this.$nextTick(() => {
        if (!this.isPanning && this.contextMenuShown != true) {
          this.invokeObjectDrawer(e.srcElement);
        }
        this.isPanning = false;
      });
    },
    registerSvgPanZoom(svgpanzoom) {
      this.svgPanObject = svgpanzoom;
    },
    elementIsValid(element) {
      if (!element || !element.tagName || !element.classList || !element.id) return false;

      return (
        element.tagName === "g" &&
        (element.classList.contains("node") ||
          element.classList.contains("edge") ||
          element.classList.contains("cluster")) &&
        element.id !== ""
      );
    },
    invokeObjectDrawer(element) {
      let sourceElement = element,
        objectId = sourceElement.getAttribute("xlink:href");

      while (!sourceElement.classList.contains("svg-container")) {
        objectId = sourceElement.getAttribute("xlink:href");

        if (objectId) break;
        else sourceElement = sourceElement.parentElement;
      }

      if (!objectId) return;

      objectId = objectId.split("\/").pop();
      this.selected = objectId;
    },
    fit() {
      if (!this.svgPanObject) return;

      this.svgPanObject.resize();
      this.svgPanObject.fit();
      this.svgPanObject.center();

      this.isPanning = false;
    },
    fitWhenKeepSize() {
      if (this.keepSize) {
        this.fit();
      }
    },
    toggleKeepSize() {
      if (!this.keepSize) {
        this.fit();
      }

      this.keepSize = !this.keepSize;
    },
  },
  destroyed() {
    if (this.svgContainerResizeObserver) this.svgContainerResizeObserver.disconnect();
  },
};
</script>
<style>
.inv-object-button-group {
  transition: all 200ms;
}
.object-side-drawer {
  bottom: 0px;
  top: unset !important;
  box-shadow: none !important;
  border-left: 1px solid ghostwhite;
  border-top: 1px solid ghostwhite;
}
.svg-container {
  width: 100%;
  height: 100%;
  cursor: grab;
}
.svg-container > div,
.svg-container > div > svg {
  height: 100%;
  width: 100%;
}
.svg-container > svg * {
  font-family: unset !important;
}
.svg-container > div {
  border: none;
}
.svg-container #thumbView {
  z-index: 110;
  background: white;
}
.svg-container #scopeContainer {
  z-index: 120;
}

.svg-container .thumbViewClass,
.svg-container .thumbnail {
  padding: 0 !important;
  margin: 0 !important;
}
.svg-container .thumbViewClass rect.scope {
  fill: var(--v-accent-base);
  stroke: var(--v-accent-base);
}
.svg-container > svg * {
  font-family: unset !important;
}

.svg-container .edge,
.svg-container .node,
.svg-container path {
  transition: all 250ms ease-in-out;
  cursor: pointer;
}
.edge {
  stroke-width: 1.5;
}
.svg-container__panning,
.svg-container__panning * {
  cursor: grabbing;
}

.svg-container [data-selected="true"],
.svg-container [data-selected="true"] text {
  text-decoration: underline;
  fill: var(--v-anchor-base);
  font-weight: bold;
}

.svg-container [data-selected="true"][data-object-type="relation"] path {
  stroke: var(--v-anchor-base);
}

.svg-container [data-expand-icon] {
  font-family: 'Font Awesome 6 Pro';
  fill: var(--v-anchor-base);
  font-size: 6px;
  font-weight: bold;
}

</style>