import backend from 'obj-fe/app/backend';
import notify from 'obj-fe/services/notifications';

/*
 * TODO: split resource registrations to modules/folders
 */

backend.registerResource('security', {
    baseUrl: CONFIG.baseUrl + 'rest/security',
    commands:{
        authRenderers:{
            method: 'GET',
            url:'authRenderers'
        },
        all:{
            method:'GET',
            url:'/permissions/assignable'
        }
    }
});

backend.registerResource('init', {
    baseUrl: CONFIG.baseUrl + 'rest',
    commands:{
        settings:{
            method:'GET',
            url:'/maintenance/settings'
        }
    }
});

backend.registerResource('objects', {
    baseUrl: CONFIG.baseUrl + 'rest/invObjs',

    // if response contains pagination
    paginationCountKey:'pageInfo.totalRowCount',
    paginationPageKey:'pageInfo.pageNumberFromOne',
    paginationLimitKey:'pageInfo.pageSize',
    paginationPagesCountKey:'pageInfo.totalPagesCount',
    paginationHasNextKey:'pageInfo.next',
    paginationHasPrevKey:'pageInfo.prev',

    // queryString builder preferences
    // queryKey: null, // if there is query Key, whole query will be stringified into one query string key
    queryPageKey: 'pageNumberFromOne',
    queryLimitKey: 'pageSize',
    // querySortKey: 'sort',

    resourceListKey:'dataInPage',

    commands:{
        one:{
            method: 'GET',
            url:'/{id}'
        },
        all:{
            method: 'GET',
            url:'/'
        },
        search:{
            baseUrl: CONFIG.baseUrl + 'rest/invSearch',
            method: 'GET',
            url:'/facetedFullText/?filterCriteria={text}',
            queryReplacePaginProps: true,
            additionalDataKeys: { categories: 'categories' },
            isList: true
        },
        historicalSnapshots:{
            method: 'GET',
            url:'/{id}/historicalSnapshots',
            queryReplacePaginProps: true,
            isList: true
        },
        firstSnapshot:{
            method: 'GET',
            url:'/{id}/firstSnapshot',
        },
        detailPanel:{
            method: 'GET',
            baseUrl: CONFIG.baseUrl,
            url: params => (params.urlTemplate || 'rest/invObjs/{id}/detailPanel').replace('{id}', encodeURIComponent(params.id)),
        },
        detailPanelTemplate:{
            method: 'GET',
            url: '/{type}/template/detailPanel',
            baseUrl: CONFIG.baseUrl,
            url: params => (params.urlTemplate || 'rest/invObjs/{type}/template').replace('{id}', encodeURIComponent(params.id)).replace('{type}', encodeURIComponent(params.type)),
        },
        detailPanelUpdate:{
            method: 'PUT',
            baseUrl: CONFIG.baseUrl,
            url: params => (params.urlTemplate || 'rest/invObjs/{id}/detailPanel').replace('{id}', encodeURIComponent(params.id)),
            onSuccess(){
                notify.success('updated');
            }
        },
        detailPanelCreate:{
            method: 'POST',
            baseUrl: CONFIG.baseUrl,
            url: params => (params.urlTemplate || 'rest/invObjs/{id}/detailPanel').replace('{id}', encodeURIComponent(params.id)).replace('{type}', encodeURIComponent(params.type)),
            onSuccess(){
                notify.success('created');
            }
        },
        detailPreview: {
            method: 'GET',
            url: '/{id}/detailPreview'
        },
        saveReport:{
            method:'POST',
            url:'/grid/{reportId}/update/{objectId}',
            onSuccess(){
                notify.success('updated');
            }
        },
        plainText:{
            method:'GET',
            url:'/{id}/plainText',
            ignoreLoading: true,
        },
        emailPlainText: {
            method: 'POST',
            url:'/{id}/plainText/email',
            headers:{
                'Content-Type': 'text/plain'
            },
            httpOpts: {
                responseType: 'text'
            },
            onSuccess(data){
                notify.success(data);
            },
            onError(err, status){
                if(status === 401) return;

                try {
                    notify.error('sending_failed', err ? JSON.parse(err.error) : '');
                    return true;
                }
                catch(err){
                    return false;
                }
            }
        },
        mapInfo:{
            method:'GET',
            url:'/{id}/mapInfo',
            ignoreLoading: true
        },
        layeredMapInfo:{
            method: 'GET',
            url: '/{id}/layeredMapInfo/default',
            ignoreLoading: true
        },

        /* Graphviz */
        graphviz:{
            method: 'GET',
            baseUrl: CONFIG.baseUrl + 'rest/',
            url: '/diagram/{diagramId}',
            ignoreLoading: true,
        },
        saveGraphviz:{
            method: 'POST',
            baseUrl: CONFIG.baseUrl + 'rest/',
            url: '/diagram/{diagramId}'
        },
        impactSvgDiagram:{
            method: 'GET',
            baseUrl: CONFIG.baseUrl + 'rest/',
            url: '{customRestUrl}',
            ignoreLoading: true
        },
        svgDiagram:{
            method: 'GET',
            baseUrl: CONFIG.baseUrl + 'rest/',
            url: '/diagram/{diagramId}/svg',
            ignoreLoading: true
        },
        svgPreview:{
            method: 'POST',
            baseUrl: CONFIG.baseUrl + 'rest/',
            url: '/diagram/{diagramId}/svgPreview'
        },
        diagramCheck:{
            method:'POST',
            baseUrl: CONFIG.baseUrl + 'rest/',
            url: '/diagram/check'
        },
        diagramAliasesSearch:{
            method:'GET',
            baseUrl: CONFIG.baseUrl + 'rest/',
            url: '/diagram/search'
        },
        suggestAttributes:{
            method:'POST',
            baseUrl: CONFIG.baseUrl + 'rest/',
            url: '/diagram/suggest'
        },
        suggestAttributeValues:{
            method:'POST',
            baseUrl: CONFIG.baseUrl + 'rest/',
            url: '/diagram/suggest/values'
        },
        customPostRestUrl:{
            method: 'POST',
            baseUrl: CONFIG.baseUrl,
            url: '{customRestUrl}',
        },
        customGetRestUrl:{
            method: 'GET',
            baseUrl: CONFIG.baseUrl,
            url: '{customRestUrl}',
        },
        fullTextContext: {
            baseUrl: CONFIG.baseUrl + 'rest/invSearch',
            method:'GET',
            url: '/fullTextContext/{id}/?filterCriteria={text}'
        },
        placement: {
            baseUrl: CONFIG.baseUrl + 'rest/invSearch',
            method:'GET',
            url: '/placement/{id}'
        },
        searchDetails: {
            baseUrl: CONFIG.baseUrl + 'rest/invSearch/searchDetails',
            method:'PUT',
        },
        nodeResources:{
            method:'GET',
            url: '/{id}/parents/nodeResources'
        },
        circuitBorders:{
            method:'GET',
            url: '/{id}/circuitBorders'
        },

        visualisation: {
            method: 'GET',
            url: '{id}/visualisation',
            ignoreLoading: true,
        },

        reportDescription:{
            method:'GET',
            url: '/grid/{reportId}/description'
        },

        reportDescriptionWithModifier:{
            method:'GET',
            url: '/grid/{reportId}/description/?reportDataModifier={reportDataModifier}'
        },

        reportData:{
            method:'POST',
            url: '/grid/{reportId}/data/{objectId}',
            isList: true,
            ignoreLoading: true
        },

        reportDataWithModifier:{
            method:'POST',
            url: '/grid/{reportId}/data/{objectId}/?reportDataModifier={reportDataModifier}',
            isList: true,
            ignoreLoading: true
        },

        correctionRequestType:{
            baseUrl: CONFIG.baseUrl + 'rest/report',
            method: 'GET',
            url: '/listValues/DATA_CORRECTION_REQUEST_TYPE'
        },

        dataCorrection: {
            method: 'POST',
            url: '/{globalId}/{fieldAttribute}/dataCorrectionRequest'
        },

        integrationPanel: {
            method: 'GET',
            url: '/{id}/integrationPanel',
            ignoreLoading: true,
        },

        updateIntegrationFields:{
            method: 'PUT',
            url: '/{id}/integrationPanel',
            onSuccess(){
                notify.success('updated');
            }
        },

        exportExcel:{
            method:'POST',
            url:'/grid/{reportId}/xlsx/{objectId}',
            responseType: 'blob'
        },
        exportExcelAsync: {
            method: 'POST',
            url: '/grid/{reportId}/xlsxAsync/{objectId}'
        },
        exportExcelBulkAsync:{
            method:'POST',
            url:'/grid/xlsxAsyncBulk/{objectId}'
        },

        exportDataAsync:{
            method: 'POST',
            baseUrl: CONFIG.baseUrl,
            url: 'rest/dataExportImport/exportAsync/grid/{objectId}'
        },
        importDataAsync: {
            method: 'UPLOAD',
            baseUrl: CONFIG.baseUrl,
            url: 'rest/dataExportImport/importAsync/grid/{objectId}'
        },
        getTabWithAttributeGroups: {
            method: 'GET',
            url: '/{globalId}/tabWithAttributeGroups/{dataSource}',
        },
        putTabWithAttributeGroups: {
            method: 'PUT',
            url: '/{globalId}/tabWithAttributeGroups/{dataSource}',
        },
        printTemplates: {
            method: 'GET',
            url: '/{globalId}/printTemplates'
        },
        printTemplate: {
            method: 'GET',
            url: '/{globalId}/print/{template}'
        },
        objectPanelButtonAction: {
            method: 'POST',
            url: '/{globalId}/touchButton/{buttonId}'
        }
    }
});
backend.registerResource('customGrids', {
    baseUrl: CONFIG.baseUrl + 'rest/customGrids',

    commands:{
        saveGrid:{
            method: 'POST',
            baseUrl: CONFIG.baseUrl,
            url: 'rest/customGrids/{customGridId}'
        },
        updateGrid:{
            method: 'PUT',
            baseUrl: CONFIG.baseUrl,
            url: 'rest/customGrids/{customGridId}'
        },
        loadGrid: {
            method: 'GET',
            baseUrl: CONFIG.baseUrl,
            url: 'rest/customGrids/{customGridId}'
        },
        suggestAttributes:{
            method:'POST',
            baseUrl: CONFIG.baseUrl + 'rest/customGrids/suggest/',
            url: 'attribute'
        },
        suggestAttributeValues:{
            method:'POST',
            baseUrl: CONFIG.baseUrl + 'rest/customGrids/suggest/',
            url: 'value'
        },
        suggestColumnAttributes:{
            method:'POST',
            baseUrl: CONFIG.baseUrl + 'rest/customGrids/suggest/column/',
            url: 'attribute'
        },
        suggestColumnAttributeValues:{
            method:'POST',
            baseUrl: CONFIG.baseUrl + 'rest/customGrids/suggest/column/',
            url: 'value'
        },
        suggestColumnValues:{
            method: 'POST',
            baseUrl: CONFIG.baseUrl + 'rest/customGrids/suggest/',
            url: 'column'
        },
        suggestExpressionConstant:{
            method: 'POST',
            baseUrl: CONFIG.baseUrl + 'rest/customGrids/suggest/',
            url: 'expressionConstant'
        },
        withPreview: {
            method: 'GET',
            baseUrl: CONFIG.baseUrl,
            url: 'rest/customGrids/withPreview/{customGridId}'
        }

    }
});

backend.registerResource('widgets', {
    baseUrl: CONFIG.baseUrl + 'rest/widgets',

    commands:{
        bookmarkEntity: {
            method: 'PUT',
            url: '/bookmarkEntity/{globalId}'
        },
        bookmarkReport:{
            method:'PUT',
            url: '/bookmarkReport/{reportId}'
        },
        getBookmarkedEntities:{
            method: 'GET',
            url: '/bookmarkedEntities'
        },
        getBookmarekdReports:{
            method: 'GET',
            url: '/bookmarekdReports'
        },
        lastVisitedEntities:{
            method:'GET',
            url: '/lastVisitedEntities'
        },
        lastVisitedReports:{
            method:'GET',
            url: '/lastVisitedReports'
        },
        sourceUrlGetMethod:{
            method: 'GET',
            baseUrl: CONFIG.baseUrl,
            url: '{customRestUrl}'
        },
        list:{
            method:'GET',
            url: '/list'
        }
    }
});

backend.registerResource('batchJobs', {
    baseUrl: CONFIG.baseUrl + 'rest/admin',

    commands:{
        all:{
            method: 'GET',
            baseUrl: CONFIG.baseUrl + 'rest/maintenance',
            url: '/feBatchJobs'
        },

        runJob: {
            baseUrl: CONFIG.baseUrl,
            url: params => params.url || params.id,
            method: 'PUT',
            httpOpts: {
                responseType: 'text'
            },
            ignoreLoading: true,
            onSuccess() { notify.success('batch_job_finished'); }
        },
        config: {
            url: '/config',
            method: 'GET'
        },

        syncGlobalToOpDb: {
            url: '/syncGlobalToOpDb',
            method: 'PUT',
            httpOpts: {
                responseType: 'text'
            },
            onSuccess() { notify.success('batch_job_scheduled'); }
        },

    }
});

backend.registerResource('importJobs', {
    baseUrl: CONFIG.baseUrl + 'rest/dataExportImport/jobs',

    // if response contains pagination
    paginationCountKey:'pageInfo.totalRowCount',
    paginationPageKey:'pageInfo.pageNumberFromOne',
    paginationLimitKey:'pageInfo.pageSize',
    paginationPagesCountKey:'pageInfo.totalPagesCount',
    paginationHasNextKey:'pageInfo.next',
    paginationHasPrevKey:'pageInfo.prev',

    // queryString builder preferences
    // queryKey: null, // if there is query Key, whole query will be stringified into one query string key
    queryPageKey: 'pageNumberFromOne',
    queryLimitKey: 'pageSize',
    // querySortKey: 'sort',

    resourceListKey:'dataInPage',

    commands:{
        all: {
            method: 'GET',
            url:'/',
            queryReplacePaginProps: true,
            isList: true
        },
        changes: {
            method: 'GET',
            url: '/{id}/detail',
            queryReplacePaginProps: true,
            isList: true,
            additionalDataKeys: { header:'header' }
        },
        remove: {
            method: 'DELETE',
            url: '/{id}/delete'
        },
        confirm: {
            method: 'PUT',
            url: '/{id}/confirmChanges'
        }
    }
});

backend.registerResource('jobs', {
    baseUrl: CONFIG.baseUrl + 'rest/jobs',

    // if response contains pagination
    paginationCountKey:'pageInfo.totalRowCount',
    paginationPageKey:'pageInfo.pageNumberFromOne',
    paginationLimitKey:'pageInfo.pageSize',
    paginationPagesCountKey:'pageInfo.totalPagesCount',
    paginationHasNextKey:'pageInfo.next',
    paginationHasPrevKey:'pageInfo.prev',

    // queryString builder preferences
    // queryKey: null, // if there is query Key, whole query will be stringified into one query string key
    queryPageKey: 'pageNumberFromOne',
    queryLimitKey: 'pageSize',
    // querySortKey: 'sort',

    resourceListKey:'dataInPage',

    commands:{
        pending:{
            url: '',
            method: 'GET'
        },
        pendingSilent:{
            url: '',
            method: 'GET',
            ignoreLoading: true
        },
        count:{
            url: '/count',
            method: 'GET'
        },
        history:{
            url: '/history',
            method: 'GET',
            queryReplacePaginProps: true,
            isList: true
        },
        historySilent:{
            url: '/history',
            method: 'GET',
            queryReplacePaginProps: true,
            isList: true,
            ignoreLoading: true
        },
        taskQueueDebugState:{
            url: '/taskQueue/debugState',
            method: 'GET'
        },
        stackTrace:{
            url: '/{id}/stackTrace',
            method: 'GET'
        },
        summaryInfo:{ url: '/{id}/summaryInfo',
            method: 'GET'
        },
        stop:{
            url: '/{id}/stop',
            method: 'PUT'
        }
    }
});

backend.registerResource('orgEntities', {
    baseUrl: CONFIG.baseUrl + 'rest/orgEntities',

    commands: {
        allRestrictions: {
            method: 'GET',
            url: '/{id}/allRestrictions',
            ignoreLoading: true,
        },

        updateRestrictions: {
            method: 'PUT',
            url: '/{id}/allRestrictions',
            onSuccess(){
                notify.success('updated');
            }
        },

        inheritedRestrictions: {
            method: 'GET',
            url: '/{id}/inheritedRestrictions',
            ignoreLoading: true,
        }
    }
});

backend.registerResource('listValues', {
    baseUrl: CONFIG.baseUrl + 'rest/admin/listValues',

    commands: {
        folders:{
            method: 'GET',
            url: '/report/lvFolders',
        },
    }
});

backend.registerResource('impactDiagrams', {
    baseUrl: CONFIG.baseUrl + 'rest/impact',

    commands: {
        impactChains: {
            method: 'GET',
            url: '/impactChains?resourceId={resourceId}&serviceId={serviceId}'
        }
    }
});

backend.registerResource('maps', {
    baseUrl: CONFIG.baseUrl + 'rest/maps',

    commands:{
        layers:{
            method:'GET',
            url:'/layers'
        },
        layerData:{
            method: 'POST',
            url: '/layerData'
        },
        entityInfo:{
            method:'GET',
            url:'/layerData/{mapId}/entityInfo/{id}'
        }
    }
});

backend.registerResource('dsl', {
    baseUrl: CONFIG.baseUrl + 'rest/dsl',

    commands:{
        emdslDefinition:{
            method:'GET',
            url:'/emdslDefinition/{context}'
        },
        emdslCreate:{
            method:'POST',
            url:'/emdslCreate/{context}/{globalId}',
            onError(err, status){
                if(status === 412) return true;
            }
        },
        emdslCreateForce:{
            method:'POST',
            url:'/emdslCreate/{context}/{globalId}/force'
        },
        emdslTemplate:{
            method: 'GET',
            url: '/emdslTemplate/{context}/{globalId}'
        },
        emdslEntitySuggest:{
            method: 'GET',
            url: '/emdslEntitySuggest/{templateName}/{emdslObjectType}'
        },
        emdslAttributeValueSuggest:{
            method: 'GET',
            url: '/emdslAttributeSuggest/{templateName}/{emdslObjectType}/{emdslAttribute}/'
            // url: '/emdslAttributeSuggest/EMDSL_SYSTEM_ARCHITECTURE/CommunicatesTo/OutageSemantics?searchToken='
        },
        emdslPreview:{
            method: 'POST',
            url: '/emdslPreview/{context}/svg'
        },
        emdslTemplatesWithContext:{
            method: 'GET',
            url: '/emdslTemplates/{globalId}'
        },
        emdslTemplatesWithoutContext:{
            method: 'GET',
            url: '/emdslTemplates'
        },

        bigPictureLazyMetadataUrl:{
            method: 'PUT',
            baseUrl: CONFIG.baseUrl + 'rest/',
            url: '{customRestUrl}',
            ignoreLoading: true
        },

        bigPictureLazyCachedSvgUrl:{
            method: 'GET',
            baseUrl: CONFIG.baseUrl + 'rest/',
            url: '{customRestUrl}',
            ignoreLoading: true
        },

        getBigPictureDsl:{
            method: 'GET',
            baseUrl: CONFIG.baseUrl,
            url: (params) => params.url
        },

        updateBigPictureDsl:{
            method: 'PUT',
            headers:{ 'Content-Type': 'text/plain' },
            baseUrl: CONFIG.baseUrl,
            url: (params) => params.url
        }
    }
});


backend.registerResource('geoserver', {
    baseUrl: CONFIG.baseUrl + '/geoserver/rest',

    commands:{
        layers:{
            method:'GET',
            url:'{customRestUrl}'
        }
    }
})

backend.registerResource('collections', {
    baseUrl: CONFIG.baseUrl + 'rest/report',

    commands:{
        collections:{
            method:'GET',
            url:'/collections'
        }
    }
});

backend.registerResource("chat", {
    baseUrl: CONFIG.baseUrl + "rest/chat",

    commands: {
        cntUnreadMessages: {
            method: "GET",
            url: "/cntUnreadMessages",
        },
        findChatRecipient: {
            method: "GET",
            url: "/findChatRecipient",
        },
        findChatroomById: {
            method: "GET",
            url: "/findChatroomById",
        },
        findDirectChatroomWithRecipient: {
            method: "GET",
            url: "/findDirectChatroomWithRecipient",
        },
        findMyChatrooms: {
            method: "GET",
            url: "/findMyChatrooms",
        },
        findPreviousMessagesForChatroom: {
            method: "GET",
            url: "/findPreviousMessagesForChatroom",
        },
        findTopicByName: {
            method: "GET",
            url: "/findTopicByName",
        },
        lastMessageInRooms: {
            method: "GET",
            url: "/lastMessageInRooms",
        },
        unreadMessages: {
            method: "GET",
            url: "/unreadMessages",
        },

        createTopicChatroom: {
            method: "POST",
            url: "/createTopicChatroom",
        },
        createDirectChatroomWithRecipient: {
            method: "POST",
            url: "/createDirectChatroomWithRecipient?recipientId={recipientId}",
        },

        addChatroomRecipients: {
            method: "PUT",
            url: "/addChatroomRecipients",
        },
        messageRead: {
            method: "PUT",
            url: "/messageRead?messageId={messageId}",
        },

        deleteChatroom: {
            method: "DELETE",
            url: "/deleteChatroom?roomId={roomId}",
        },
        removeChatroomRecipients: {
            method: "DELETE",
            url: "/removeChatroomRecipients",
        },
    },
});

backend.registerResource('customRestUrls', {
    baseUrl: CONFIG.baseUrl,

    commands:{
        getRestUrl: {
            baseUrl: CONFIG.baseUrl,
            method: "GET",
            url: "{customRestUrl}"
        },
        postRestUrl: {
            baseUrl: CONFIG.baseUrl,
            method: "POST",
            url: "{customRestUrl}"
        },
        deleteRestUrl: {
            baseUrl: CONFIG.baseUrl,
            method: "DELETE",
            url: "{customRestUrl}"
        }
    }

})

backend.registerResource('dms', {
    baseUrl: CONFIG.baseUrl + 'rest/dms',
    commands:{
        documents:{
            method:'POST',
            url:'/documents'
        },
        documentRedirect:{
            method:'GET',
            url:'/documentRedirect/{documentId}'
        },
        homePageRedirect:{
            method:'GET',
            url:'/homePageRedirect'
        }
    }
});

backend.registerResource('wizard', {
    baseUrl: CONFIG.baseUrl + 'rest/wizard',
    commands:{
        start:{
            method:'POST',
            url:'/{id}/start' // id - templateName
        },
        back:{
            method:'POST',
            url:'/{id}/back' // id - wizardRunId
        },
        next:{
            method:'POST',
            url:'/{id}/next' // id - wizardRunId
        }
    }
});

backend.registerResource('spreadsheetValidation', {
    baseUrl: CONFIG.baseUrl + 'rest/report',
    commands:{
        validate:{
            method:'POST',
            url:'/resolve/{id}' // id - reportName
        }
    }
});

// backend.registerResource('users', {
//     baseUrl: CONFIG.baseUrl + 'rest/security/users',

//     commands:{
//         create:{
//             method:'POST',
//             url:'create'
//         },
//         remove:{
//             method:'DELETE',
//             url:'/{id}'
//         },
//         update:{
//             method:'PUT',
//             url:'/{id}'
//         },
//         all: {
//             method: 'POST',
//             url: '',
//             isList:true,
//             resourceListKey: 'dataInPage',
//             paginationCountKey:'pageInfo.totalRowCount',
//             paginationPageKey:'pageInfo.pageNumberFromOne',
//             paginationPagesCountKey:'pageInfo.totalPagesCount',
//             paginationHasNextKey:'pageInfo.next',
//             paginationHasPrevKey:'pageInfo.prev'
//         },
//         active: {
//             method:'GET',
//             url:'/active',
//             isList:true
//         }
//     }
// });

// backend.registerResource('stats', {
//     baseUrl: CONFIG.baseUrl + 'rest/stats',
//     commands:{
//         userStatsCount:{
//             url:'/user/count',
//             method:'GET'
//         },
//         documentUploadCount:{
//             url: '/document/upload/count',
//             method: 'GET'
//         },
//         documentDownloadCount:{
//             url: '/document/download/count',
//             method: 'GET'
//         },
//         serviceRequestCount:{
//             url: '/service/count',
//             method: 'GET'
//         },
//         allStats:{
//             url: '/all',
//             method: 'GET'
//         }
//     }
// });

// backend.registerResource('persons', {
//     baseUrl: CONFIG.baseUrl + 'rest/',
//     commands:{
//         all:{
//             method:'GET',
//             url:'/orgEntities/persons',
//             resourceListKey:'this'
//         }
//     }
// });

// backend.registerResource('adminReports', {
//     baseUrl: CONFIG.baseUrl + 'rest/report',
//     commands:{
//         descriptions:{
//             method:'GET',
//             url:'/descriptions'
//         },
//         description:{
//             method:'GET',
//             url:'/{name}/description'
//         },
//         one:{
//             method:'GET',
//             url:'/reportEditor/{id}'
//         },
//         create:{
//             method:'POST',
//             url:'/reportEditor'
//         },
//         update:{
//             method:'PUT',
//             url:'/reportEditor/{id}'
//         },
//         remove:{
//             method:'DELETE',
//             url:'/reportEditor/{id}'
//         },
//         checkConsistency:{
//             method:'GET',
//             url: '/reportEditor/{id}/consistencyCheck'
//         },

//         cellTypes:{
//             method:'GET',
//             url:'/reportEditor/cellTypes'
//         },
//         columnEditors:{
//             method:'GET',
//             url:'/reportEditor/columnEditors'
//         },
//         columnRenderers:{
//             method:'GET',
//             url:'/reportEditor/columnRenderers'
//         },
//         filterRenderers:{
//             method:'GET',
//             url:'/reportEditor/filterRenderers'
//         },
//         filterValueEnhancers:{
//             method:'GET',
//             url:'/reportEditor/filterValueEnhancers'
//         },
//     }
// });
export default backend;
