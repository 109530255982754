import {
    Lexer,
    CstParser,
    EMPTY_ALT,
    createSyntaxDiagramsCode
} from 'chevrotain';
import {
    createInterpreter
} from './bpdsl-interpreter.js';
import {
    createTokenList,
    createToken,
    tokens
} from './bpdsl-tokens.js';

import DslProcessor from './bpdsl-processor.js';
import DslSuggester from './bpdsl-suggester.js';

// DSL Example
// (resource)-[]->()
// (premenna:Typ1|Typ2)

// (resource:Resource|ConstructionUnit)-[:Placed]->(p1)
// (p1)-[:Placed]->(p1)
// (p1)-[:Placed]->(p1)

// (resource)<-[:Placed]-(ch1)
// (ch1)<-[:Placed]-(ch1)
// (resource)-[:Location]->(:Site)
// (p1)-[:Location]->(:Site)

// Renderer:EnrichmentRenderer {selector=resource|ch1:Resource; attributes=ids.name,ids.description}
// Renderer:Hierarchy {hierarchyChildren=Placed,RunningOn}
// Renderer:SiteRoomRack
// Renderer:DefaultRenderer
// Renderer:EnrichmentRenderer/:ids,ids.description

class BpDslParser extends CstParser {
    constructor() {
        super(createTokenList(), {
            // by default the error recovery flag is **false**
            // use recoveryEnabled flag in the IParserConfig object to enable enable it.
            recoveryEnabled: true,
            dynamicTokensEnabled: true
        });

        const $ = this;

        $.RULE('rootExpression', () => {

            $.OPTION(() => {
                $.CONSUME(tokens.LineBreak, { LABEL: 'lineBreak' });
            });

            $.MANY_SEP({
                SEP: tokens.LineBreak,
                DEF: () => {
                    $.OR([
                        { ALT: () => $.SUBRULE($.relation, { LABEL: 'relation' }) },
                        { ALT: () => $.SUBRULE($.renderer, { LABEL: 'renderer' }) },
                        { ALT: () => $.SUBRULE($.hop, { LABEL: 'hop' }) },
                    ]);
                }
            });
        });

        $.RULE('relation', () => {
            // (relationVarsDeclaration)<-[relationVarsDeclaration]-(relationVarsDeclaration)
            // (relationVarsDeclaration)-[relationVarsDeclaration]->(relationVarsDeclaration)
            // (relationVarsDeclaration)-->(relationVarsDeclaration)
            // (relationVarsDeclaration)<--(relationVarsDeclaration)

            $.SUBRULE1($.relationNode, { LABEL: 'relationNodeLeft' });

            $.OR1([
                { ALT: () => $.CONSUME1(tokens.ArrowLine, { LABEL: 'arrowLine' }) },
                { ALT: () => $.CONSUME1(tokens.ArrowLeft, { LABEL: 'arrowLeft' }) },
            ]);
            
            $.OPTION(() => {
                $.SUBRULE($.relationEdge, { LABEL: 'relationEdge' });
            });
            
            $.OR2([
                { ALT: () => $.CONSUME2(tokens.ArrowLine, { LABEL: 'arrowLine' }) },
                { ALT: () => $.CONSUME2(tokens.ArrowRight, { LABEL: 'arrowRight' }) },
            ]);
            
            $.SUBRULE2($.relationNode, { LABEL: 'relationNodeRight' });
        });

        $.RULE('relationNode', () => {
            $.CONSUME(tokens.BracketLeft, { LABEL: 'bracketLeft' });
            $.SUBRULE($.relationVarsDeclaration, { LABEL: 'relationVarsDeclaration' });
            $.CONSUME(tokens.BracketRight, { LABEL: 'bracketRight' });
        });

        $.RULE('relationEdge', () => {
            $.CONSUME(tokens.SquareBracketLeft, { LABEL: 'squareBracketLeft' });
            $.SUBRULE($.relationVarsDeclaration, { LABEL: 'relationVarsDeclaration' });
            $.CONSUME(tokens.SquareBracketRight, { LABEL: 'squareBracketRight' });
        });

        $.RULE('relationVarsDeclaration', () => {
            // tag
            // tag:Type
            // :Type
            // tag1|tag2:Type1|Type2

            $.MANY_SEP1({
                SEP: tokens.Pipe,
                DEF: () => $.CONSUME1(tokens.Identifier, { LABEL: 'tag' })
            });

            $.OPTION(() => {
                $.CONSUME2(tokens.Collon, { LABEL: 'tagTypesSeparator' });

                $.AT_LEAST_ONE_SEP({
                    SEP: tokens.Pipe,
                    DEF: () => $.CONSUME3(tokens.Identifier, { LABEL: 'type' })
                });
            });
        });

        $.RULE('renderer', () => {
            // Renderer: name { attributes }
            $.CONSUME(tokens.Renderer, { LABEL: 'prefix' });
            $.CONSUME(tokens.Collon, { LABEL: 'collon' });
            $.CONSUME(tokens.Identifier, { LABEL: 'name' });
            $.SUBRULE($.attributes, { LABEL: 'attributes' });
        });

        $.RULE('hop', () => {
            // @NextHopPart: name name { attributes }
            $.CONSUME(tokens.HopPrefix, { LABEL: 'prefix' });
            $.CONSUME1(tokens.Identifier, { LABEL: 'type' });
            $.CONSUME(tokens.Collon, { LABEL: 'collon' });
            $.AT_LEAST_ONE(() => $.CONSUME2(tokens.Identifier, { LABEL: 'name' }))
            $.SUBRULE($.attributes, { LABEL: 'attributes' });
        });

        $.RULE('attributes', () => {
            // { attr1=val1; attr2=; }
            $.CONSUME(tokens.CurlyBracketLeft, { LABEL: 'curlyBracketsLeft' });

            $.MANY_SEP({
                SEP: tokens.SemiCollon,
                DEF: () => $.SUBRULE($.attribute, { LABEL: 'attribute' })
            });

            $.CONSUME(tokens.CurlyBracketRight, { LABEL: 'curlyBracketsRight' });
        });

        $.RULE('attribute', () => {
            $.CONSUME(tokens.Identifier, { LABEL: 'name' });

            $.CONSUME(tokens.Equal, { LABEL: 'equal' });

            $.OPTION(() => {
                $.SUBRULE($.attributeValue, { LABEL: 'attributeValue' });
            });
        });

        $.RULE('attributeValue', () => {
            $.MANY(() => {
                $.OR(
                    [
                        tokens.Identifier,
                        tokens.True,
                        tokens.False,
                        tokens.Number,
                        tokens.String,
                        tokens.StringQuoted,
                        tokens.Collon,
                        tokens.Sharp,
                        tokens.Comma
                    ].map(token => ({ ALT: () => $.CONSUME(token, { LABEL: 'value' }) }))
                );
            });
        });

        // $.RULE('entityType', () => {
        //     $.CONSUME(tokens.EntityTypeAbstract, { LABEL: 'entityType' });
        // });

        // $.RULE('entityIdentifier', ()=>{
        //     $.SUBRULE($.identifierValue, { LABEL: 'entityIdentifier'});
        // });

        // $.RULE('entityContent', () => {
        //     $.MANY(() => {
        //         $.OR([
        //             { ALT: () => $.SUBRULE($.relation, { LABEL: 'relation' }) },
        //             { ALT: () => $.SUBRULE($.attribute, { LABEL: 'attribute' }) },
        //             // { ALT: () => EMPTY_ALT } - not needed, because comma is optional now
        //         ]);

        //         $.OPTION(() => {
        //             $.CONSUME(tokens.Comma);
        //         });
        //     });

        //     // TODO: decide MANY_SEP or MANY will be better
        //     // $.MANY_SEP({
        //     //     SEP: tokens.Comma,
        //     //     DEF: () => {
        //     //         $.OR([
        //     //             { ALT: () => $.SUBRULE($.relation, { LABEL: 'relation' }) },
        //     //             { ALT: () => $.SUBRULE($.attribute, { LABEL: 'attribute' }) },
        //     //             { ALT: () => EMPTY_ALT }
        //     //         ]);
        //     //     }
        //     // });
        // });
        
        // $.RULE('attributes', () => {
        //     $.MANY(() => {
        //         $.OR([
        //             { ALT: () => $.SUBRULE($.attribute, { LABEL: 'attribute' }) },
        //             // { ALT: () => EMPTY_ALT } - not needed, because comma is optional now
        //         ])

        //         $.OPTION(() => {
        //             $.CONSUME(tokens.Comma);
        //         });
        //     });
        // });

        // $.RULE('attribute', () => {
        //     $.SUBRULE($.attributeName, { LABEL: 'attributeName' });
        //     $.CONSUME(tokens.Equal, { LABEL: 'equal' });
        //     $.SUBRULE($.attributeValue, { LABEL: 'attributeValue' });
        // });

        // $.RULE('attributeName', () => {
        //     $.OR([
        //         { ALT: () => $.CONSUME(tokens.AttributeNameAbstract, { LABEL: 'attributeName' }) },

        //         // default fallback due to autocomplete, because if attribute name or relation name is not complete, parser does not know what it may be
        //         { ALT: () => $.CONSUME(tokens.Identifier, { LABEL: 'attributeName' }) }
        //     ]);
        // });

        // $.RULE('relation', () => {
        //     $.CONSUME(tokens.RelationNameAbstract, { LABEL:'relationType' })
        //     $.OPTION({
        //         DEF: ()=> {
        //             $.CONSUME(tokens.CurlyBracketLeft, { LABEL: 'curlyBracket' });
        //             $.SUBRULE($.attributes, {LABEL: 'attributes'});
        //             $.CONSUME(tokens.CurlyBracketRight, { LABEL: 'curlyBracket' });
        //         }
        //     });
        //     $.CONSUME(tokens.Arrow, { LABEL: 'arrow' });
        //     $.SUBRULE($.relationTarget, { LABEL: 'relationTarget' });
        // });

        // $.RULE('relationTarget', () => {
        //     $.OR([
        //         {
        //             GATE: () => $.LA(3).tokenType === tokens.CurlyBracketLeft,
        //             ALT: () => $.SUBRULE2($.entity, { LABEL: 'inlineEntity' })
        //         },
        //         {
        //             ALT: () => $.SUBRULE2($.identifierValue, { LABEL: 'relationTo' })
        //         },
        //         {
        //             ALT: () => $.SUBRULE2($.multipleRelationsTo, { LABEL: 'multipleRelationsTo' })
        //         }
        //     ]);
        // });

        // $.RULE('multipleRelationsTo', ()=>{
        //     $.CONSUME(tokens.SquareBracketLeft, { LABEL: 'squareBracket' });
            
        //     $.MANY(() => {
        //         $.OR([
        //             {
        //                 GATE: () => $.LA(3).tokenType === tokens.CurlyBracketLeft,
        //                 ALT: () => $.SUBRULE2($.entity, { LABEL: 'inlineEntity' })
        //             },
        //             { ALT: () => $.SUBRULE2($.identifierValue, { LABEL: 'relationTo' }) },
        //             // { ALT: () => EMPTY_ALT } - not needed, because comma is optional now
        //         ]);

        //         $.OPTION(() => {
        //             $.CONSUME(tokens.Comma);
        //         });
        //     });

        //     // TODO: decide MANY_SEP or MANY will be better
        //     // $.MANY_SEP({
        //     //     SEP: tokens.Comma,
        //     //     DEF: () => {
        //     //         $.OR([
        //     //             {
        //     //                 GATE: () => $.LA(3).tokenType === tokens.CurlyBracketLeft,
        //     //                 ALT: () => $.SUBRULE2($.entity, { LABEL: 'inlineEntity' })
        //     //             },
        //     //             { ALT: () => $.SUBRULE2($.identifierValue, { LABEL: 'relationTo' }) },
        //     //             { ALT: () => EMPTY_ALT }
        //     //         ])
        //     //     }
        //     // });

        //     $.CONSUME(tokens.SquareBracketRight, { LABEL: 'squareBracket' });
        // });
        
        // $.RULE('identifierValue', () => {
        //     $.OR([{
        //         ALT: ()=> $.CONSUME1(tokens.Identifier)
        //     },{
        //         ALT: ()=> $.CONSUME2(tokens.StringQuoted)
        //     }]);
        // });

        // $.RULE('attributeValue', () => {
        //     $.OR([{
        //         ALT: () => $.CONSUME(tokens.False, { LABEL: 'value' })
        //     },{ 
        //         ALT: () => $.CONSUME(tokens.True, { LABEL: 'value' })
        //     },{
        //         ALT: () => $.CONSUME(tokens.Identifier, { LABEL: 'value' })
        //     },{
        //         ALT: () => $.CONSUME(tokens.Number, { LABEL: 'value' })
        //     },{
        //         ALT: () => $.CONSUME(tokens.StringQuoted, { LABEL: 'value' })
        //     }]);
        // });

        this.performSelfAnalysis();
    }
}

function repairTokenColumnsPositions(lexResult, text){

    let lineStartOffsets = { '0':0 };
    let lines = text.split('\n');
    lines.forEach((line, index) => {
        let prevLine = lines[ index-1 ];
        if(index > 0) lineStartOffsets[ index ] = lineStartOffsets[ index - 1 ] + prevLine.length + 1;
    });

    lexResult.tokens = lexResult.tokens.map(token => {
        token.startColumn = token.startOffset - lineStartOffsets[ token.startLine - 1 ] + 1;
        token.endColumn = token.endOffset - lineStartOffsets[ token.endLine - 1 ] + 1;
        return token;
    });

    return lexResult;
}

function parse(text) {
    const parser = this;

    // 1. Tokenize the input.
    // need to repairTokenPosColumns, because in fault tolerant mode, columns are moved and are not in sync with source text
    const lexResult = repairTokenColumnsPositions(this.emLexer.tokenize(text), text);
    const emLexer = this.emLexer;

    // 2. Parse the Tokens vector.
    const bpDslParser = this.bpDslParser;
    bpDslParser.input = lexResult.tokens;
    const cst = bpDslParser.rootExpression();

    // 3. Perform semantics using a CstVisitor.
    // Note that separation of concerns between the syntactic analysis (parsing) and the semantics.
    const rootTokenContext = createInterpreter(this.BaseCstVisitor).visit(cst);

    const dslDefinition = this.dslDefinition;
    const processedResult = new DslProcessor(dslDefinition).process(rootTokenContext);

    this.text = text;

    this.result = {
        getNextTokenSuggestions(line, column, cb){
            return new DslSuggester(bpDslParser, emLexer, processedResult.rootTokenContext, bpDslParser.errors, dslDefinition, {
                entitySuggestion: parser.suggestEntityName,
                attributeValueSuggestion: parser.suggestAttributeValue
            }).suggest(line, column, cb);
        },

        tokens: lexResult.tokens,
        lexErrors: lexResult.errors,
        parseErrors: bpDslParser.errors.concat(processedResult.errors || []), // append interpreter errors
        value: processedResult,
        // aliases: interpreter.aliases,
        dslDefinition: {
            entityTypes: this.dslDefinition.nodes.map(node => node.name),
            entitiesDefinitions: this.dslDefinition.nodes
        },
        isValid: lexResult.errors.length === 0 && bpDslParser.errors.length === 0,
        validated: false,
        validate: validateResources.bind(this),

        validQueue: [],
        onValidated(cb) {
            if (this.validated) cb();
            else this.validQueue.push(cb);
        },
        validationFinished() {
            this.isValid = this.lexErrors.length === 0 && this.parseErrors.filter(e => !e.isWarning).length === 0;
            this.validated = true;
            this.validQueue.forEach(cb => cb());
            this.validQueue = [];
        }
    };

    // trigger aditional async validations
    this.result.validate();

    return this.result;
}

function validateResources(cb) {
    let resourcesToValidate = [];

    let parser = this;

    function done() {
        parser.result.validationFinished();
        if (cb) cb();
    }

    if (!parser.validateResources) return done();

    parser.validateResources(resourcesToValidate.map(e => e.data), result => {

        result.forEach((err, index) => {
            if (err) {
                let data = resourcesToValidate[index];
                err.token = data.data.token;
                this.result.parseErrors.push(err);
            }
        });

        done();
    });
}

// helper for generating dsl syntax diagram html
// function saveSyntaxDiagramAsHTML(){
//     const html = createSyntaxDiagramsCode( new BpDslParser(createTokenList()).getSerializedGastProductions());
//     const tempLink = document.createElement('a');
//     const taBlob = new Blob([html], {type: 'text/html'});
//     tempLink.setAttribute('href', URL.createObjectURL(taBlob));
//     tempLink.setAttribute('download', 'syntax-diagram.html');
//     tempLink.click();
    
//     URL.revokeObjectURL(tempLink.href);
// }
// saveSyntaxDiagramAsHTML();

export default function Parser(opts = {}) {

    this.dslDefinition = opts.dslDefinition;
    this.parse = parse;
    this.getNextTokenSuggestions = function(line, column, cb){ 
        return this.result.getNextTokenSuggestions(line, column, cb);
    };

    this.validateResources = opts.validateResources;
    this.suggestEntityName = opts.suggestEntityName;
    this.suggestAttributeValue = opts.suggestAttributeValue;
    
    const emdslNodes = (this.dslDefinition || {}).nodes || [];
    const entityTypeNamesObj = {};
    const relationNamesObj = {};
    const attributeNamesObj = {};

    emdslNodes.forEach(node => {
        entityTypeNamesObj[node.name] = true;
        (node.attributes || []).forEach(attribute => attributeNamesObj[attribute.name] = true);
        (node.relations || []).forEach(relation => {
            relationNamesObj[relation.name] = true;
            (relation.attributes || []).forEach(attribute => attributeNamesObj[attribute.name] = true);
        });
    });

    const tokens = createTokenList(Object.keys(entityTypeNamesObj), Object.keys(relationNamesObj), Object.keys(attributeNamesObj));

    this.bpDslParser = new BpDslParser(tokens);
    this.BaseCstVisitor = this.bpDslParser.getBaseCstVisitorConstructor();
    this.emLexer = new Lexer(tokens);
}