<template>
    <InvBpdslEditor
        :value="value"
        @input-text="value => $emit('input', value)"
        :placeholder="'enter_big_picture_code_here' | translate"
        @valid="isValid => editorContentIsValid = isValid"
        auto-height 
        @parser-result="result => parserResult = result"
        :dslDefinition="bpdslDefinition"
        @is-dirty="editorIsDirty = $event">
    </InvBpdslEditor>
</template>

<style scoped>

</style>

<script>
    import InvBpdslEditor from '../../dsl/bpdsl/inv-bpdsl-editor.vue';

    export default {
        components:{
            InvBpdslEditor
        },
        props:{
            value: String
        },
        data(){
            return {
                editorIsDirty: false,
                editorContentIsValid: true,
                parserResult: null,

                bpdslDefinition:{
                    nodes:[],
                }
            };
        }
    }
</script>