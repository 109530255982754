import { CONTEXT_TYPES, checkQuoteText, checkIfQuotedClosed, checkUnquotePrefix } from './bpdsl-types.js';

const DEBUG_MODE = false;

class SuggestionCollection extends Array {
    constructor(values, SuggClass, prefix){
        super();

        values = values || [];
        let suggestions = values.map(value => new SuggClass(value));

        // calc score if needed
        if(prefix) suggestions.forEach(suggestion => {
            const text = (suggestion.displayText || '').toLowerCase();
            const isAtIndex = text.indexOf(prefix.toLowerCase());

            if(isAtIndex === 0) suggestion.score = 2;
            else if(isAtIndex > 0) suggestion.score = 1;
        });

        // sort by score and displayText a-z
        suggestions = suggestions.sort((a,b) => {
            if(a.score > b.score) return -1;
            else if(a.score < b.score) return 1;
            else if(a.displayText < b.displayText) return -1;
            else if(a.displayText > b.displayText) return 1;
            else return 0;
        });

        // fill
        suggestions.forEach(suggestion => {
            if(prefix){
                if(suggestion.score > 0) this.push(suggestion);
            }
            else this.push(suggestion);
        });
    }

    prepend(suggestions){
        Array.prototype.unshift.apply(this, this.compareFilterDupliciteValues(suggestions));
        return this;
    }

    append(suggestions){
        Array.prototype.push.apply(this, this.compareFilterDupliciteValues(suggestions));
        return this;
    }

    compareFilterDupliciteValues(suggestions){
        const currSuggestionsByValue = {};
        this.forEach(sugg => currSuggestionsByValue[ sugg.text ] = sugg);
        return suggestions.filter(sugg => !currSuggestionsByValue[ sugg.text ]);
    }
}

class Suggestion {
    constructor(text, displayText, type){
        this.text = text;
        this.displayText = displayText || text;
        this.score = 0;
        this.type = type;
        this.disabled = false;
        this.hint = null;
        // this.offset = ''
        // this.className

        this.setType(type);
    }

    setType(type){
        this.className = type + '-suggestion custom-suggestion';
    }

    checkQuoteText(text, alwaysQuote){ return checkQuoteText(text, alwaysQuote); }
}

class InfoSuggestion extends Suggestion {
    constructor(text){
        super(text, text, 'INFO');
        this.disabled = true;
        this.hint = () => {};
    }
}

class SyntaxSuggestion extends Suggestion {
    constructor(text){
        super(text, text, 'SYNTAX');
    }
}

class EntityAliasSuggestion extends Suggestion {
    constructor(text){
        super(text, text, 'ENTITY_ALIAS');
        text = this.checkQuoteText(text, true);
        this.displayText = text;
        this.text = text + ' ';
    }
}

class EntitySuggestion extends Suggestion {
    constructor(entity){
        super(entity.alias, entity.alias, 'INVENTORY_OBJECT');
        
        let suggestText = this.checkQuoteText(entity.alias) + ' {\n';
        
        suggestText += Object.entries(entity.attributes || {}).map(([key, value]) => {
            return key + ' = ' + this.checkQuoteText(entity.attributes[key]);
        }).join(',\n');

        suggestText += '\n} ';

        this.text = suggestText;
    }
}

class EntityTypeSuggestion extends Suggestion {
    constructor(text){
        super(text, text, 'ENTITY_TYPE');
        this.text = this.checkQuoteText(text) + ' ';
    }
}

class RelationNameSuggestion extends Suggestion {
    constructor(text){
        super(text, text, 'RELATION_NAME');
        this.text = this.checkQuoteText(text) + ' -> ';
    }
}

class AttributeNameSuggestion extends Suggestion {
    constructor(text){
        super(text, text, 'ATTRIBUTE_NAME');
        this.text = this.checkQuoteText(text) + ' = ';
    }
}

class AttributeValueSuggestion extends Suggestion {
    constructor(text){
        super(text, text, 'ATTRIBUTE_VALUE');
        text = this.checkQuoteText(text, true);
        this.displayText = text;
        this.text = text + ' ';
    }
}

class EntityReferenceSuggestion extends Suggestion {
    constructor(text){
        super(text, text, 'ENTITY_REFERENCE');
        text = this.checkQuoteText(text, true);
        this.displayText = text;
        this.text = text + ' ';
    }
}

export default class DslSuggestor {
    constructor(dslParser, dslLexer, rootTokenContext, syntaxErrors, dslDefinition, loaders){
        // this.rootTokenContext = rootTokenContext;

        // this.parser = dslParser;
        // this.lexer = dslLexer;

        // this.syntaxErrors = syntaxErrors;

        // this.loaders = loaders || {}; // entitySuggestion
        
        // this.definedTypeByAlias = {};
        // this.rootTokenContext.getChildrenByContextType(CONTEXT_TYPES.ENTITY_ALIAS).forEach(aliasTokenCtx => {
        //     if(aliasTokenCtx.value) this.definedTypeByAlias[ aliasTokenCtx.value ] = (aliasTokenCtx.parent || {}).typeName;
        // });
        // this.definedAliases = Object.keys(this.definedTypeByAlias);

        // this.alavilableTypes = dslDefinition.nodes.map(node => node.name);

        // this.alavilableAttributesByTypeName = {};
        // dslDefinition.nodes.map(node => this.alavilableAttributesByTypeName[ node.name ] = node.attributes);

        // this.alavilableRelationsByTypeName = {};
        // dslDefinition.nodes.map(node => this.alavilableRelationsByTypeName[ node.name ] = node.relations);

        // // check suggestions map is complete
        // const missingSuggTypes = [];
        // for(let key in CONTEXT_TYPES) {
        //     if(!SUGGESTIONS_BY_CONTEXT_TYPE[ key ]) missingSuggTypes.push(key);
        // }
        // if(missingSuggTypes.length > 0) throw new Error('Missing suggestions for types: "'+missingSuggTypes.join(', ')+'"');
    }

    isAfterTokenCtx(boundaries, line, column){
        return boundaries.endLine < line || (boundaries.endLine === line && boundaries.endColumn < column);
    }

    isInsideTokenCtx(boundaries, line, column){
        return  boundaries.startLine < line && boundaries.endLine > line ||
                boundaries.startLine === line && boundaries.startColumn < column && // "<=" vs "=" - this is because when cursor standing at the begining of token, it should not be inside
                boundaries.endLine === line && boundaries.endColumn >= column;
    }

    tokenContainsSyntaxError(token){
        if(!token || !this.syntaxErrors) return;
        return this.syntaxErrors.find(sError => sError.token === token);
    }

    getSyntacticSuggestions(targetTokenCtx){
        let tokens = [];
        let prevTokenCtxs = [];
        if(targetTokenCtx) this.rootTokenContext.children.find(tokenCtx => {
            if(tokenCtx.token) tokens.push(tokenCtx.token);
            prevTokenCtxs.push(tokenCtx);
            return tokenCtx === targetTokenCtx;
        });

        function isParentOfTargetTokenCtx(tokenCtx){
            let parent = targetTokenCtx.parent;
            while(parent){
                if(parent === tokenCtx) return true;
                parent = parent.parent;
            }
            return false;
        }

        let nextTokenSuggestions = this.parser.computeContentAssist('rootExpression', tokens);

        if(DEBUG_MODE && tokens.length > 0 && nextTokenSuggestions.length === 0) {
            console.warn('Suggestions: NO tokens suggested from chevrotain computeContentAssist method, syntaxErrors count is', this.syntaxErrors.length);
        }

        // try to fix suggestion tokens if content assist fails, by removing all non-parent token groups,
        // e.g.: prev attribute, entity or relation is not valid, try to skip it
        if(tokens.length > 0 && nextTokenSuggestions.length === 0 && this.syntaxErrors.length > 0) {
            // find invalid tokens and remove their parent ENTITY, ATTRIBUTE, or RELATION from list
            let tokensBlackListSet = new Set();
            prevTokenCtxs.forEach(tokenCtx => {
                // check if this token contains syntax error
                let tokenSyntaxError = this.tokenContainsSyntaxError(tokenCtx.token);
                if(tokenSyntaxError) {
                    let ignoreOnSyntaxError = (SUGGESTIONS_BY_CONTEXT_TYPE[ tokenCtx.contextType ] || {}).ignoreOnSyntaxError;
                    let forceIgnoreParent = false;
                    let tokenCtxsToIgnore = ignoreOnSyntaxError ? ignoreOnSyntaxError.call(this, tokenCtx, tokenSyntaxError, (forceIgnore = true) => forceIgnoreParent = forceIgnore) : null;

                    if(tokenCtxsToIgnore) {
                        tokenCtxsToIgnore = Array.isArray(tokenCtxsToIgnore) ? tokenCtxsToIgnore : [tokenCtxsToIgnore];
                        tokenCtxsToIgnore.forEach(tokenCtx => {
                            if(tokenCtx && (forceIgnoreParent || !isParentOfTargetTokenCtx(tokenCtx))){ // cannot remove own parent
                                [tokenCtx].concat(tokenCtx.getAllDescendants()).forEach(child => {
                                    if(child.token) tokensBlackListSet.add(child.token);
                                });
                            }
                        });
                    }
                }
            });

            // filter black listed tokens, and re-parse result to ensure tokens will not be affected by removing some from it
            const repairedText = tokens.filter(token => !tokensBlackListSet.has(token)).map(token => token.image).join(' ');
            tokens = this.lexer.tokenize(repairedText).tokens;
            nextTokenSuggestions = this.parser.computeContentAssist('rootExpression', tokens);

            if(DEBUG_MODE) {
                console.warn('Suggestions: removing invalid code sequences ', Array.from(tokensBlackListSet).map(token => token.image).join(' '));
            }
        }

        if(DEBUG_MODE) {
            console.warn('Suggestions: getting raw tokens from parser content assist for: "' + tokens.map(token => token.image).join(' ') + '"', targetTokenCtx, tokens);
        }

        return nextTokenSuggestions;
    }

    getCursorPrefix(token, line, column){
        // TODO: support multiline tokens if needed
        const offsetFromEnd = column - token.endColumn;
        return offsetFromEnd < 0 ? token.image.slice(0, offsetFromEnd) : token.image;
    }

    getLastSequenceMatchIndex(array, subArray){
        if(subArray.length === 0) return -1;
        const subArrayCount = subArray.length - 1;

        let matchIndex = -1;
        for(let arrayIndex=array.length-1; arrayIndex>=0; arrayIndex--){
            for(let subArrayIndex=subArrayCount; subArrayIndex>=0; subArrayIndex--){
                if(array[ arrayIndex - (subArrayCount - subArrayIndex) ] === subArray[subArrayIndex]) {
                    matchIndex = arrayIndex - subArrayCount;
                }
                else {
                    matchIndex = -1;
                    break;
                }
            }
            if(matchIndex > -1) return matchIndex;
        }

        return matchIndex;
    }

    suggest(line, column, cb, cursorStandsAtClosedTokenValue = false){
        return [];

        if(DEBUG_MODE) {
            console.warn('Suggestions: --------------------------------------------------------------------------');
            console.warn('Suggestions: starting suggestion process at line ' + line + ', column ' + column);
        }

        // 1. find prev and current token context - where cursor stands
        let prevTokenCtx;
        let currentTokenCtx = this.rootTokenContext.children.find(tokenCtx => {
            const boundaries = tokenCtx.getTokenBoundaries();
            if(!boundaries) return;
    
            if(this.isAfterTokenCtx(boundaries, line, column)) {
                prevTokenCtx = tokenCtx;
            }
            else return this.isInsideTokenCtx(boundaries, line, column);
        });

        if(cursorStandsAtClosedTokenValue) {
            prevTokenCtx = currentTokenCtx;
            currentTokenCtx = null;
        }

        // 2. calc prefix - only when current token
        let rawPrefix = currentTokenCtx ? this.getCursorPrefix(currentTokenCtx.token, line, column) : '';
        let prefix = checkUnquotePrefix(rawPrefix);

        if(DEBUG_MODE) {
            console.warn('Suggestions: calculated prefix is "'+prefix+'", raw prefix is "'+rawPrefix+'"');
        }

        // 3. check if this is case when cursor stands at the end of closed token
        //    if so, we need to repeat suggestions for next tokens
        if(currentTokenCtx) {
            const isClosedChecker = SUGGESTIONS_BY_CONTEXT_TYPE[currentTokenCtx.contextType].isClosed;
            const isStandingOnClosedType = isClosedChecker ? isClosedChecker.call(this, rawPrefix, currentTokenCtx, prevTokenCtx) : false;
            
            if(isStandingOnClosedType) {
                if(DEBUG_MODE) {
                    console.warn('Suggestions: cursor is standing directly after closed token of matched context type "'+currentTokenCtx.contextType+'", repeating suggestion process as for next token');
                }

                return this.suggest(line, column, cb, true);
            }
        }
    
        // 4. get matched SUGGESTIONS_BY_CONTEXT_TYPE
        const nextTokenSuggestions = this.getSyntacticSuggestions(prevTokenCtx);
        const nextTokenCtxTypesSet = new Set();
        nextTokenSuggestions.forEach(tokenSuggestion => {
            const extendedRuleStack = tokenSuggestion.ruleStack.slice();
            const nextTokenType = tokenSuggestion.nextTokenType || {};

            if(nextTokenType.typeId) extendedRuleStack.push(nextTokenType.typeId);
            if(nextTokenType.name) extendedRuleStack.push(nextTokenType.name);
            if(nextTokenType.text) extendedRuleStack.push(nextTokenType.text);
            if(nextTokenType.PATTERN) extendedRuleStack.push(nextTokenType.PATTERN);

            // match SUGGESTIONS_BY_CONTEXT_TYPE
            let maxScore = 0, matchedType;
            for(let type in SUGGESTIONS_BY_CONTEXT_TYPE) {
                const matchScore = this.getLastSequenceMatchIndex(extendedRuleStack, SUGGESTIONS_BY_CONTEXT_TYPE[type].match || []);
                if(maxScore < matchScore){
                    matchedType = type;
                    maxScore = matchScore;
                }
            }

            if(DEBUG_MODE) {
                console.warn('Suggestions: matched context type "'+matchedType+'" with score "'+maxScore+'" for tags', extendedRuleStack);
            }

            if(matchedType) nextTokenCtxTypesSet.add(matchedType);
        });

        // 5. call all matched SUGGESTIONS_BY_CONTEXT_TYPE handlers asynchronously
        const suggestionLoadPromises = [];

        // 6. sort suggestion handlers by priority to ensure right ordering and filtering,
        //    because duplicate suggestion values are removed
        const nextTokenCtxTypesSorted = Array.from(nextTokenCtxTypesSet).sort((a,b) => { // sort by priority
            a = SUGGESTIONS_BY_CONTEXT_TYPE[a];
            b = SUGGESTIONS_BY_CONTEXT_TYPE[b];

            const aPriority = a.priority || 0;
            const bPriority = b.priority || 0;
            
            if(aPriority > bPriority) return -1;
            else if(aPriority < bPriority) return 1;
            else return 0;
        });

        if(DEBUG_MODE) {
            console.warn('Suggestions: token context types after sorting:', nextTokenCtxTypesSorted);
        }

        nextTokenCtxTypesSorted.forEach(tokenCtxType => {
            let handler = SUGGESTIONS_BY_CONTEXT_TYPE[tokenCtxType].handler;
            if(handler) suggestionLoadPromises.push(new Promise(resolve => {
                handler.call(this, prefix, currentTokenCtx, prevTokenCtx, resolve);
            }));
        });

        // 7. concat partial suggestions from all type handlers
        let suggestions = new SuggestionCollection();
        if(suggestionLoadPromises.length === 0) suggestions.append([ new InfoSuggestion('Invalid code, don\'t know what to suggest') ]);

        Promise.all(suggestionLoadPromises).then(suggestionResults => {
            suggestionResults.forEach(suggestionsFromType => suggestions.append(suggestionsFromType));

            if(suggestions.length === 0) suggestions.append([ new InfoSuggestion('No suggested values') ]);

            if(prefix || rawPrefix){ // need to replace prefix
                const boundaries = currentTokenCtx.getTokenBoundaries();
                cb(suggestions, { line: boundaries.startLine, column: boundaries.startColumn }, { line: boundaries.endLine, column: boundaries.endColumn }, true);
            }
            else cb(suggestions, { line, column }, { line, column }, false);
        });

        return nextTokenCtxTypesSorted;
    }
}

function ignoreThisAndPrevOnSyntaxError(thisContextType, prevContextTypes){
    return function(tokenCtx, syntaxError){
        const parent = tokenCtx.getParentByContextType(thisContextType);
        const prevParentToIgnore = parent ? parent.getPreviousByContextType(prevContextTypes) : null;

        function nextComma(tokenCtx){
            if(!tokenCtx) return null;
            const next = tokenCtx.getNext();
            return next && (next.contextType === CONTEXT_TYPES.ATTRIBUTE_SEPARATOR) ? next : null;
        }

        return [ parent, nextComma(parent), prevParentToIgnore, nextComma(prevParentToIgnore) ];
    }
}

const SUGGESTIONS_BY_CONTEXT_TYPE = {
    [ CONTEXT_TYPES.ROOT ]: {}, // dummy, only collection

    [ CONTEXT_TYPES.ENTITY ]: {}, // dummy, only collection

    [ CONTEXT_TYPES.ENTITY_TYPE ]: { // suggest entity types or aliases
        match:[ 'entityType', 'EntityTypeAbstract' ],
        isClosed(rawPrefix, tokenCtx){ return checkIfQuotedClosed(rawPrefix); },
        priority: 1,
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            const targetTokenCtx = tokenCtx || prevTokenCtx
            const nearestParent = targetTokenCtx ? targetTokenCtx.getParentByContextType([CONTEXT_TYPES.ENTITY, CONTEXT_TYPES.RELATION]) || {} : {};
            const isInsideRelation = nearestParent.contextType === CONTEXT_TYPES.RELATION;
            const allowedTypes = isInsideRelation ? nearestParent.type.referenceTypes : null;
            let availableTypes = this.alavilableTypes;
            let availableAliases = this.definedAliases;

            if(allowedTypes) {
                availableTypes = availableTypes.filter(type => allowedTypes.includes(type));
                availableAliases = Object.entries(this.definedTypeByAlias).filter(([alias,type]) => allowedTypes.includes(type)).map(([alias,type]) => alias);
            }

            const typeSuggestions = new SuggestionCollection(availableTypes, EntityTypeSuggestion, prefix);
            const aliasSuggestions = new SuggestionCollection(availableAliases, EntityAliasSuggestion, prefix);

            cb(aliasSuggestions.append(typeSuggestions));
        }
    },

    [ CONTEXT_TYPES.ENTITY_ALIAS ]: {
        match:[ 'entity', 'entityIdentifier' ],
        isClosed(rawPrefix, tokenCtx){ return checkIfQuotedClosed(rawPrefix); },
        priority: 3,
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            const entityType = prevTokenCtx && prevTokenCtx.value;
            const isValidEntityType = this.alavilableTypes.includes(entityType);

            if(isValidEntityType && this.loaders.entitySuggestion){
                this.loaders.entitySuggestion({ emdslObjectType: entityType, searchToken: prefix }, suggestions => {
                    cb(new SuggestionCollection(suggestions, EntitySuggestion));
                });
            }
            else if(isValidEntityType) {
                cb(new SuggestionCollection(this.definedAliases, EntityAliasSuggestion, prefix));
            }
            else cb([]);
        }
    },

    [ CONTEXT_TYPES.ENTITY_BODY_START ]: {
        match:[ 'entity', 'CurlyBracketLeft' ],
        isClosed(rawPrefix, tokenCtx){ return rawPrefix === '{'; },
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            cb(new SuggestionCollection(['{'], SyntaxSuggestion, prefix));
        }
    },

    [ CONTEXT_TYPES.ENTITY_BODY_END ]: {
        match:[ 'entity', 'CurlyBracketRight' ],
        isClosed(rawPrefix, tokenCtx){ return rawPrefix === '}'; },
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            cb(new SuggestionCollection(['}'], SyntaxSuggestion, prefix));
        },
        ignoreOnSyntaxError(tokenCtx, syntaxError, setForceIgnoreParent){
            // if error on last curly brackets has some syntax error, whole parent may be ignored
            setForceIgnoreParent(true);
            return tokenCtx.getParentByContextType([CONTEXT_TYPES.ENTITY]);
        }
    },

    [ CONTEXT_TYPES.ATTRIBUTE ]: {}, // dummy, only collection

    [ CONTEXT_TYPES.ATTRIBUTE_NAME ]: {
        match:[ 'attributeName', 'AttributeNameAbstract' ],
        priority: 2,
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            const parentRelationOrEntityTokenCtx = (tokenCtx || prevTokenCtx).getParentByContextType([ CONTEXT_TYPES.RELATION, CONTEXT_TYPES.ENTITY ]);
            let availableAttributesObj = (parentRelationOrEntityTokenCtx.type || {}).attributes || {};

            const attrNameSuggestions = new SuggestionCollection(Object.keys(availableAttributesObj), AttributeNameSuggestion, prefix);
            cb(attrNameSuggestions);
        },
        ignoreOnSyntaxError: ignoreThisAndPrevOnSyntaxError(CONTEXT_TYPES.ATTRIBUTE, [ CONTEXT_TYPES.RELATION, CONTEXT_TYPES.ATTRIBUTE ])
    },

    [ CONTEXT_TYPES.ATTRIBUTE_OPERATOR ]: {
        match:[ '=' ],
        isClosed(rawPrefix, tokenCtx){ return rawPrefix === '='; },
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            cb(new SuggestionCollection(['='], SyntaxSuggestion, prefix));
        }
    },

    [ CONTEXT_TYPES.ATTRIBUTE_VALUE ]: {
        match:[ 'attribute', 'attributeValue' ],
        isClosed(rawPrefix, tokenCtx){ return checkIfQuotedClosed(rawPrefix); },
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            const parentRelationOrEntityTokenCtx = (tokenCtx || prevTokenCtx).getParentByContextType([ CONTEXT_TYPES.RELATION, CONTEXT_TYPES.ENTITY ]);
            const attributeName = ((tokenCtx || prevTokenCtx).getPreviousByContextType(CONTEXT_TYPES.ATTRIBUTE_NAME) || {}).value;
            const attributeDef = parentRelationOrEntityTokenCtx && (parentRelationOrEntityTokenCtx.type || {}).attributes ? parentRelationOrEntityTokenCtx.type.attributes[attributeName] : null;

            if(attributeDef && attributeDef.type === 'REFERENCE') {
                const allowedEntityTypeNames = attributeDef.referenceTypes || [];
                const allowedAliases = Object.entries(this.definedTypeByAlias).filter(([alias, typeName]) => allowedEntityTypeNames.includes(typeName)).map(([alias, typeName]) => alias);

                cb(new SuggestionCollection(allowedAliases, EntityReferenceSuggestion));
            }
            else if(parentRelationOrEntityTokenCtx && parentRelationOrEntityTokenCtx.typeName && attributeName && this.loaders.attributeValueSuggestion) {
                // get unique identifier attribute
                const idAttribute = parentRelationOrEntityTokenCtx.attributes.find(attribute => (attribute.attrDef || {}).uniqueIdentifier);

                this.loaders.attributeValueSuggestion({
                    id: idAttribute ? idAttribute.value : '', // entityTypeIdentifier is the relation if entity alias is null
                    emdslAttribute: attributeName,
                    emdslObjectType: parentRelationOrEntityTokenCtx.typeName,
                    searchToken: prefix
                }, suggestions => cb(new SuggestionCollection(suggestions, AttributeValueSuggestion)));
            }
            else cb([]);
        }
    },

    [ CONTEXT_TYPES.ATTRIBUTE_SEPARATOR ]: {
        match:[ ',' ],
        isClosed(rawPrefix, tokenCtx){ return rawPrefix.indexOf(',') > -1; },
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            cb(new SuggestionCollection([','], SyntaxSuggestion, prefix));
        },
        ignoreOnSyntaxError(tokenCtx, syntaxError){
            // if error on attribute separator, skip prev attribute and separator token
            return [tokenCtx].concat( tokenCtx.getPreviousByContextType([CONTEXT_TYPES.ATTRIBUTE, CONTEXT_TYPES.RELATION]) );
        }
    },

    [ CONTEXT_TYPES.RELATION ]: {}, // dummy, only collection

    [ CONTEXT_TYPES.RELATION_TYPE ]: {
        match:[ 'relation', 'RelationNameAbstract' ],
        priority: 1,
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            const parentEntity = (tokenCtx || prevTokenCtx).getParentByContextType(CONTEXT_TYPES.ENTITY);
            if(!parentEntity || !parentEntity.type) return cb([]);

            const availableRelationsObj = parentEntity.type.relations;
            const relationNameSuggestions = new SuggestionCollection(Object.keys(availableRelationsObj), RelationNameSuggestion, prefix);

            cb(relationNameSuggestions);
        },
        ignoreOnSyntaxError: ignoreThisAndPrevOnSyntaxError(CONTEXT_TYPES.RELATION, [ CONTEXT_TYPES.RELATION, CONTEXT_TYPES.ATTRIBUTE ])
    },

    [ CONTEXT_TYPES.RELATION_ENTITY_ALIAS ]: {
        match:[ 'relationTarget', 'identifierValue' ],
        isClosed(rawPrefix, tokenCtx){ return checkIfQuotedClosed(rawPrefix); },
        priority: 4,
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            const parentRelationTokenCtx = (tokenCtx || prevTokenCtx).getParentByContextType(CONTEXT_TYPES.RELATION);
            const allowedEntityTypeNames = parentRelationTokenCtx ? (parentRelationTokenCtx.type || {}).referenceTypes || null : null;
            const allowedAliases = allowedEntityTypeNames ? Object.entries(this.definedTypeByAlias).filter(([alias, typeName]) => allowedEntityTypeNames.includes(typeName)).map(([alias, typeName]) => alias) : this.definedAliases;

            const aliasSuggestions = new SuggestionCollection(allowedAliases, EntityReferenceSuggestion, prefix);
            cb(aliasSuggestions);
        }
    },

    [ CONTEXT_TYPES.RELATION_OPERATOR ]: {
        match:[ '->' ],
        isClosed(rawPrefix, tokenCtx){ return rawPrefix === '->'; },
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            cb(new SuggestionCollection(['->'], SyntaxSuggestion, prefix));
        }
    },

    [ CONTEXT_TYPES.RELATION_LIST_START ]: {
        match:[ '[' ],
        isClosed(rawPrefix, tokenCtx){ return rawPrefix === '['; },
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            cb(new SuggestionCollection(['['], SyntaxSuggestion, prefix));
        }
    },
    
    [ CONTEXT_TYPES.RELATION_LIST_END ]: {
        match:[ ']' ],
        isClosed(rawPrefix, tokenCtx){ return rawPrefix === ']'; },
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            cb(new SuggestionCollection([']'], SyntaxSuggestion, prefix));
        },
        ignoreOnSyntaxError: ignoreThisAndPrevOnSyntaxError(CONTEXT_TYPES.RELATION, [ CONTEXT_TYPES.RELATION, CONTEXT_TYPES.ATTRIBUTE ])
    },

    [ CONTEXT_TYPES.RELATION_ATTRIBUTES_START ]: {
        match:[ 'relation', 'CurlyBracketLeft' ],
        isClosed(rawPrefix, tokenCtx){ return rawPrefix === '{'; },
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            cb(new SuggestionCollection(['{'], SyntaxSuggestion, prefix));
        }
    },

    [ CONTEXT_TYPES.RELATION_ATTRIBUTES_END ]: {
        match:[ 'relation', 'CurlyBracketRight' ],
        isClosed(rawPrefix, tokenCtx){ return rawPrefix === '}'; },
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            cb(new SuggestionCollection(['}'], SyntaxSuggestion, prefix));
        },
        ignoreOnSyntaxError: ignoreThisAndPrevOnSyntaxError(CONTEXT_TYPES.RELATION, [ CONTEXT_TYPES.RELATION, CONTEXT_TYPES.ATTRIBUTE ])
    },

    [ CONTEXT_TYPES.RELATION_LIST_SEPARATOR ]: {
        match:[ 'multipleRelationsTo', 'Comma' ],
        isClosed(rawPrefix, tokenCtx){ return rawPrefix.indexOf(',') > -1; },
        handler(prefix, tokenCtx, prevTokenCtx, cb){
            cb(new SuggestionCollection([','], SyntaxSuggestion, prefix));
        }
    },
};